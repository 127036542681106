import { Grid } from 'react-feather';
import MuiButton from '@mui/material/Button';

import styles from './DashboardTabs.module.scss';

const DashboardTabsHomeButton = () => (
  <MuiButton
    href="#/dashboards"
    color="primary"
    variant="contained"
    square
    noPadding
    size="small"
    sx={{
      height: 38,
      width: 38,
      minWidth: 38,
    }}
  >
    <Grid className={styles.buttonIcon} size={14} />
  </MuiButton>
);

export default DashboardTabsHomeButton;

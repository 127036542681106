import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getDashboardCountAndFirstId } from '@/utils';
import { useGetDashboardsQuery, useCreateDashboardMutation } from '@/store/apis/dashboardsApi';

// Custom Components
import { Button, ErrorBoundary } from '@/components';
import DashboardTabs from './DashboardTabs/DashboardTabs';
import DashboardView from './DashboardView/DashboardView';
import DashboardPageErrorFallback from './DashboardPageErrorFallback';

// Others
import './index.scss';
import styles from './DashboardPage.module.scss';

const DashboardPage = () => {
  const navigate = useNavigate();
  const { isLoading } = useGetDashboardsQuery();
  const [createDashboard, { isLoading: isLoadingCreateDashboard }] = useCreateDashboardMutation();
  const { id } = useParams();
  const { items } = useSelector((state) => state.dashboards);
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);

  const itemsData = Object.values(items) || [];

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  //   if (mediaQuery.matches) {
  //     setIsPredefinedDarkMode(true);
  //   }
  // }, []);

  useEffect(() => {
    const { firstId } = getDashboardCountAndFirstId(dashboardIds, items);
    if (!id && dashboardIds.length >= 1) {
      navigate(`/dashboards/view/${firstId}`);
    }
  }, [id, dashboardIds, items, navigate]);

  const handleCreate = async () => {
    const { data } = await createDashboard();
    navigate(`/dashboards/view/${data.id}`);
  };

  return (
    <ErrorBoundary FallbackComponent={DashboardPageErrorFallback}>
      <div className={clsx([styles.container])}>
        <div className={styles.header}>
          <DashboardTabs isLoading={isLoading} />
          <div className={styles.actions}>
            {/* <MakeCrashButton message='🐞 App Error: Make Crash executed' title='Make Crash' /> */}
            {/* <ThemeButton isPredefinedDarkMode={isPredefinedDarkMode} /> */}
          </div>
        </div>
        {id && <DashboardView isLoading={isLoading} key={id} />}
        {(!isLoading && !id && itemsData.length < 1) && (
          <div className={styles.noDashboard}>
            <Button
              isLoading={isLoadingCreateDashboard}
              onClick={handleCreate}
            >
              Add New Dashboard
            </Button>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default DashboardPage;

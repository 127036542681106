import React from 'react';
import styles from './ConfirmModal.module.scss';
import Button from '../Button';
import Modal from '../Modal';

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirming,
  title,
  text,
}) => (
  <Modal
    close={onClose}
    isOpen={isOpen}
    title={title}
    renderContent={() => (
      <div>
        <div className={styles.body}>{text}</div>
        <div className={styles.footer}>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onConfirm} isLoading={isConfirming}>
            Confirm
          </Button>
        </div>
      </div>
    )}
  />
);

export default ConfirmModal;

// @ts-nocheck
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {
  Box, Typography, Divider, Alert, TextField, Autocomplete,
} from '@mui/material';
import clsx from 'clsx';
import { useIdentitySubordinatesGetQuery, useGetOrganizationsQuery } from '@/store/apis/identityApi';
import { useGetDashboardRecentQuery, useGetDashboardQuery, useShareDashboardMutation } from '@/store/apis/dashboardsApi';
import { USER_ROLES } from '@/constants/roles';
import useScrollBlock from '@/hooks/useScrollBlock';
import { Modal, Button } from '@/components';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';
import useUserIdentity from '../hooks/useUserIdentity';
import { removeDuplicates } from '../helpers/dashboardHelpers';
import UserSection from './UserSection';
import CustomInputWithChips from './CustomInputWithChips';
import DashboardShareWithSkeleton from '../Skeleton/DashboardShareWithSkeleton';
import style from './Share.module.scss';

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: 150,
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'hsl(var(--hover-light-border))',
    '& fieldset': { border: 'none' },
    '&:hover fieldset': { borderColor: 'transparent' },
    fontSize: '16px',
    padding: '0 8px',
  },
  '& .MuiInputBase-input': {
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&:hover .MuiOutlinedInput-root': {
    backgroundColor: 'hsl(var(--hover-border))',
  },
  '& .MuiAutocomplete-paper': {
    fontSize: '12px',
  },
}));

const getIsUserInOrganization = ({ organizationIdentityId, userIdentity }) => Boolean(
  (userIdentity.hierarchy || [])
    .find((hierarchyItem) => hierarchyItem.childIdentityId === organizationIdentityId),
);

const getIsQlooUser = (userIdentity) => getIsUserInOrganization({
  organizationIdentityId: '42924822-32d0-11ee-938f-02d6856f817b',
  userIdentity,
});

const ShareWith = ({ title, dashboard, disabled }) => {
  const { dashboardActive } = useSelector((state) => state.dashboards);
  const { byId } = useSelector((state) => state.identities);
  const user = useUserIdentity(dashboardActive);

  const [blockScroll, allowScroll] = useScrollBlock();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [selectedOrg, setSelectedOrg] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedOrgsWithAllUsers, setSelectedOrgsWithAllUsers] = useState([]);
  const [permission, setPermission] = useState(`{${USER_ROLES.VIEWER}}`);
  const [isMessageError, setIsMessageError] = useState(false);

  const { identityId: organizationId } = selectedOrg;

  const { refetch: refetchCurrentDashboard } = useGetDashboardQuery({ id: dashboard?.id }, { skip: !dashboard });
  const { data: organizationsData } = useGetOrganizationsQuery({});
  const { refetch: refetchDashboardRecent } = useGetDashboardRecentQuery({});

  const { data: organizationPersonnelDashboard, isLoading: isLoadingOrganizationPersonnelDashboard } = useIdentitySubordinatesGetQuery({ identityId: organizationId }, { skip: !organizationId });
  const [shareDashboard, { isLoading: isLoadingShareDashboard }] = useShareDashboardMutation();

  const peopleWithAccess = useMemo(() => {
    return dashboardActive?.identityLinks
      ?.filter((identityLink) => identityLink.identityId !== user?.identityId && byId[identityLink.identityId])
      ?.map((identityLink) => ({
        ...byId[identityLink.identityId],
        ...identityLink,
      })) || [];
  }, [dashboardActive, user]);
  const orgWithAccess = useMemo(() => {
    if (organizationsData && organizationId) {
      return peopleWithAccess
        .filter((person) => organizationsData
          .some((org) => org.identityId === person.identityId));
    }
    return [];
  }, [peopleWithAccess, organizationsData, organizationId]);

  const modifiedSelectedOrg = useMemo(() => ({
    ...selectedOrg,
    // fullName: selectedOrg.name,
    // identityId: organizationId,
  }), [selectedOrg, organizationId]);
  useMemo(() => {
    if (organizationsData && user) {
      setSelectedOrg(
        organizationsData
          .find((organization) => getIsUserInOrganization({
            organizationIdentityId: organization.identityId, userIdentity: user,
          }))
        || organizationsData[0],
      );
    }
  }, [organizationsData, user]);
  const isSelectedOrgChecked = useMemo(() => {
    return selectedOrgsWithAllUsers.some((org) => org.identityId === organizationId);
  }, [selectedOrgsWithAllUsers, organizationId]);
  const uniqueData = useMemo(() => removeDuplicates(organizationPersonnelDashboard || [], 'identityId'), [organizationPersonnelDashboard]);

  const isQlooUser = useMemo(() => Boolean(getIsQlooUser(user)), [user]);

  useEffect(() => {
    if (isMessageError) {
      const timer = setTimeout(() => {
        setIsMessageError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isMessageError]);

  const handleOpen = () => {
    setOpen(true);
    blockScroll();
  };

  const handleBackInvite = () => {
    setSearchInput('');
    setSelectedUsers([]);
    setSelectedOrgsWithAllUsers([]);
  };

  const handleClose = () => {
    setOpen(false);
    setPermission(`{${USER_ROLES.VIEWER}}`);
    handleBackInvite();
    allowScroll();
  };

  const handleConfirm = async () => {
    setIsMessageError(false);

    const allIdentityIds = [...selectedUsers.map((user) => user.identityId), ...selectedOrgsWithAllUsers.flatMap((org) => org.users.map((user) => user.identityId))];

    try {
      const result = await shareDashboard({
        dashboardId: dashboard.dashboardId,
        identityIds: `{${allIdentityIds.join(',')}}`,
        grantedPermissionTagIds: permission,
      });
      if (result.error) {
        setIsMessageError(true);
        throw new Error('Error sending invitation');
      }
      await refetchDashboardRecent();
      await refetchCurrentDashboard();
      handleBackInvite();
    } catch (error) {
      setIsMessageError(true);
    }
  };
  const handleSelectOrg = (org) => {
    setSelectedOrg(org);
  };

  const usersWithoutAccess = uniqueData.filter(

    (user) => !peopleWithAccess.some((accessUser) => accessUser.identityId === user.identityId)
      && user.fullName.toLowerCase().includes(searchInput.toLowerCase()),
  );

  const currentOrgUsersWithAccess = peopleWithAccess?.filter((person) => !orgWithAccess.some((orgWithAccess) => orgWithAccess.identityId === person.identityId)
    && person.fullName.toLowerCase().includes(searchInput.toLowerCase())).map((user) => ({ ...user, grantedPermissionTagIds: peopleWithAccess.find((personWithAccess) => personWithAccess.identityId === user.identityId).grantedPermissionTagIds }));
  const filteredOrgWithAccess = orgWithAccess.filter((user) => user.fullName.toLowerCase().includes(searchInput.toLowerCase()));

  const getUpdatedSelectedUsers = (user, users) => (users.some((selectedUser) => selectedUser.identityId === user.identityId)
    ? users.filter((selectedUser) => selectedUser.identityId !== user.identityId)
    : [...users, user]);
  const handleUserClick = (user) => {
    if (user.identityId === modifiedSelectedOrg.identityId) {
      setSelectedUsers((prevUsers) => getUpdatedSelectedUsers(user, prevUsers));
      setSelectedOrgsWithAllUsers((prevOrgs) => prevOrgs.filter((org) => org.identityId !== modifiedSelectedOrg.identityId));
    } else {
      isSelectedOrgChecked
        ? setSelectedOrgsWithAllUsers((prevOrgs) => prevOrgs.map((org) => (org.identityId === organizationId
          ? {
            ...org,
            users: getUpdatedSelectedUsers(user, org.users),
          }
          : org)))
        : setSelectedUsers((prevUsers) => getUpdatedSelectedUsers(user, prevUsers));
    }
  };

  const isUserSelected = (user, users) => users.some((selectedUser) => selectedUser.identityId === user.identityId);
  const checkSelected = (user) => (isSelectedOrgChecked
    ? selectedOrgsWithAllUsers.some((org) => org.identityId === organizationId
      && isUserSelected(user, org.users))
    : isUserSelected(user, selectedUsers));
  const renderUserSection = (title, users, showSelectPermission = false) => {
    if (users.length > 0) {
      return (
        <UserSection
          title={title}
          dashboardId={dashboard.dashboardId}
          users={users}
          onUserClick={handleUserClick}
          checkSelected={checkSelected}
          showSelectPermission={showSelectPermission}
        />
      );
    }
    return <></>;
  };

  const handleOrgChange = useCallback((event, newValue) => {
    const org = organizationsData.find((org) => org.identityId === newValue.identityId);
    handleSelectOrg(org);
  }, [organizationsData]);

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <Box
        className={clsx({ [style.containerShare]: true, [style.containerShareDisabled]: disabled })}
        onClick={handleOpen}
      >
        <PersonAddAltIcon color="disabled" />
        {title && (
          <Typography variant="subtitle2">
            {title}
          </Typography>
        )}
      </Box>
      <Modal
        isOpen={open}
        close={handleClose}
        className={style.modalShare}
        title={(
          <Box display="flex" gap={0.5} alignItems="center">
            <Typography variant="h6" fontWeight={600}>Shared with</Typography>
          </Box>
        )}
        renderContent={() => (
          <>
            {isMessageError && (
              <Alert severity="error" sx={{ width: '100%' }}>
                Sorry, something unexpected happened, please try again.
              </Alert>
            )}
            <Box
              width={650}
              height="100%"
              pt={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="space-between"
              position="relative"
            >
              <Box width="100%" maxHeight="100%" display="flex" flexDirection="column" gap={2} paddingBottom={4}>
                {isQlooUser && (
                  <Box margin="auto" display="flex" flexDirection="column" gap={0.5}>
                    <StyledAutocomplete
                      disableClearable
                      options={organizationsData || []}
                      value={selectedOrg}
                      inputValue={inputValue}
                      getOptionLabel={(option) => option.fullName}
                      renderOption={(props, option) => (
                        <li {...props} key={option.identityId} style={{ fontSize: 14 }}>
                          {option.fullName}
                        </li>
                      )}
                      onChange={handleOrgChange}
                      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                      renderInput={(params) => <TextField {...params} sx={{ width: 130 }} />}
                    />
                  </Box>
                )}
                <Box display="flex" flexDirection="column" pl={2} pr={2} gap={0.5}>
                  <CustomInputWithChips
                    isQlooUser={isQlooUser}
                    selectedUsers={selectedUsers}
                    selectedOrgs={selectedOrgsWithAllUsers}
                    setSelectedUsers={setSelectedUsers}
                    setSelectedOrgs={setSelectedOrgsWithAllUsers}
                    setPermission={setPermission}
                    setSearchInput={setSearchInput}
                    searchInput={searchInput}
                  />
                </Box>

                <Box height="100%" overflow="auto" display="flex" flexDirection="column" gap={1.5} style={{ marginBottom: 102 }}>
                  {isLoadingOrganizationPersonnelDashboard
                    ? <DashboardShareWithSkeleton />
                    : currentOrgUsersWithAccess.length === 0 && usersWithoutAccess.length === 0 && !selectedOrg ? (
                      <Typography margin={2} color="textSecondary">No users found</Typography>
                    ) : (
                      <>
                        {!orgWithAccess.some((org) => org.identityId === organizationId) && renderUserSection('Organization', [modifiedSelectedOrg])}

                        {renderUserSection('Organizations with access', filteredOrgWithAccess, true)}

                        {(searchInput === '' && currentOrgUsersWithAccess.length < 1 && !isSelectedOrgChecked)
                          && renderUserSection('Suggestions', usersWithoutAccess.slice(0, 3))}

                        {((peopleWithAccess.length > 0)
                          || (searchInput !== '' && peopleWithAccess.length > 0) || isSelectedOrgChecked)
                          && renderUserSection('People with access', currentOrgUsersWithAccess, true)}
                        {(searchInput || isSelectedOrgChecked) && renderUserSection('Not in dashboard', usersWithoutAccess)}
                      </>
                    )}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                width="100%"
                padding="0 16px 8px 8px"
                position="sticky"
                bottom={0}
                left={0}
                backgroundColor="white"
                zIndex={11}
                gap={2}
              >
                <Divider sx={{ width: '100%', marginTop: 0 }} />
                <Button
                  onClick={handleConfirm}
                  isLoading={isLoadingShareDashboard}
                  disabled={selectedUsers.length === 0 && selectedOrgsWithAllUsers.length === 0}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </>
        )}
      />
    </FeatureNoAccessTooltip>
  );
};

export default ShareWith;

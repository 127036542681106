import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import clsx from 'clsx';
import { ConfirmModal } from '@/components';
import { useDeleteDashboardMutation } from '@/store/apis/dashboardsApi';
import useScrollBlock from '@/hooks/useScrollBlock';
import style from './DashboardDelete.module.scss';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';

const DashboardDelete = ({
  dashboard, label, onConfirmAdditional, disabled,
}) => {
  const { dashboardId, id } = dashboard;
  const [deleteDashboard, { isLoading: isLoadingConfirm }] = useDeleteDashboardMutation();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  const handleDeleteClose = () => {
    allowScroll();
    setDeleteOpen(false);
  };

  const handleConfirm = async () => {
    if (onConfirmAdditional) {
      onConfirmAdditional();
    }
    await deleteDashboard({ dashboardId, id });
    setDeleteOpen(false);
    allowScroll();
  };

  const handleOpenDelete = () => {
    setDeleteOpen(true);
    blockScroll();
  };

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <Box
        className={clsx({ [style.container]: true, [style.containerDisabled]: disabled })}
        onClick={handleOpenDelete}
      >
        <DeleteOutlineIcon color="disabled" />
        {label && <Typography variant="subtitle2">{label}</Typography>}
      </Box>
      <ConfirmModal
        isOpen={deleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleConfirm}
        isConfirming={isLoadingConfirm}
        text="Are you sure you want to delete?"
        title="Confirm Delete"
      />
    </FeatureNoAccessTooltip>
  );
};

export default DashboardDelete;

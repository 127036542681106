import clsx from 'clsx';
import Downshift from 'downshift';
import React, {
  useMemo, useRef, useState,
} from 'react';
import { Search } from 'react-feather';
import { useSelector } from 'react-redux';
import useDebounce from '@v1/lib/useDebounce';
import { LoaderBase } from '@v1/components/Loader';
import styles from './Omnisearch.module.scss';
import OmnisearchResults from './OmnisearchResults';
import Chip from '../Chip';
import EntityText from '../EntityText';
import Input from '../Input';
import useOmnisearchResults from './useOmnisearchResults';
import CategorySelect from '@/components/CategorySelect/CategorySelect';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';

const SearchInput = ({
  onClick, search, inputOnChange, isLoading,
}) => (
  <div
    className={clsx({
      [styles.field]: true,
    })}
    onClick={onClick}
  >
    {isLoading ? <LoaderBase isLoading={isLoading} resetMargin size={18} /> : <Search size={18} strokeWidth={2} />}
    <div className={styles.inputContainer}>
      <Input
        autoFocus
        className={styles.input}
        onChange={inputOnChange}
        placeholder="Search by keyword"
        value={search}
      />
    </div>
  </div>
);

const Omnisearch = ({ downshift, onChange, value }) => {
  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen,
    toggleMenu,
  } = downshift;

  const inputRef = useRef(null);
  const inputProps = getInputProps({ ref: inputRef });

  const entityById = useSelector((state) => state.entities.byId);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');

  const debouncedSearch = useDebounce(search, 250);

  const handleSearchInputChange = (inputValue, e) => {
    inputProps.onChange(e);
    setSearch(inputValue);
  };

  const omnisearchResults = useOmnisearchResults({ search: debouncedSearch, category });

  const handleDeleteValueByIndex = (index) => () => {
    const nextValue = [...value];
    nextValue.splice(index, 1);
    onChange(nextValue);
  };

  const handleClickSearchInput = () => {
    toggleMenu();
    if (!isOpen) {
      inputRef.current?.focus();
    }
  };

  const handleSelectCategory = (urn) => {
    setCategory(urn);
  };

  return (
    <div className={styles.container}>
      <div className={styles.fieldsContainer}>
        <SearchInput
          inputOnChange={handleSearchInputChange}
          search={search}
          onClick={handleClickSearchInput}
          isLoading={omnisearchResults.isLoading}
        />
        <CategorySelect value={category} onChange={handleSelectCategory} className={styles.categorySelect} />
      </div>
      {(value && !!value.length) && (
        <div className={styles.chipsContainer}>
          {value.map((entityId, i) => {
            const entity = entityById[entityId];
            return (
              <Chip
                key={entityId}
                onDelete={handleDeleteValueByIndex(i)}
                variant="contained"
                className={styles.chip}
                style={{
                  '--dynamic-category-color': (entity?.types || []).length > 0
                    ? `var(--category-${formatCategoryUrnToCssVar(entity.types[0])})` : 'hsl(var(--gray-ghost))',
                  '--dynamic-category-secondary-color':
                        (entity?.types || []).length > 0
                          ? `var(--category-${formatCategoryUrnToCssVar(entity.types[0])}-secondary)`
                          : 'hsl(var(--gray-light))',
                }}
                deleteIconClassName={styles.chipDeleteIcon}
              >
                <EntityText className={styles.chipText} entityId={entityId} />
              </Chip>
            );
          })}
        </div>
      )}
      {debouncedSearch && (
        <OmnisearchResults
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          omnisearchResults={omnisearchResults}
          value={value}
        />
      )}
    </div>
  );
};

const OmnisearchContainer = ({ onChange, ...props }) => {
  const value = useMemo(() => props.value || [], [props.value]);

  const handleSelection = (itemToBeAdded) => {
    const nextValue = [...value];
    if (nextValue.indexOf(itemToBeAdded) === -1) {
      nextValue.push(itemToBeAdded);
      onChange(nextValue);
    } else {
      onChange(nextValue.filter((item) => item !== itemToBeAdded));
    }
  };

  return (
    <Downshift onChange={handleSelection} selectedItem={null}>
      {(downshift) => (
        <Omnisearch {...downshift.getRootProps()} downshift={downshift} onChange={onChange} value={value} />
      )}
    </Downshift>
  );
};

export default OmnisearchContainer;

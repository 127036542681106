// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from '../utils/customBaseQuery';

const FEATURE_TAG = 'identity';
/// see https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#advanced-invalidation-with-abstract-tag-ids
const featureListObject = { type: FEATURE_TAG, id: 'LIST' };

// Define a service using a base URL and expected endpoints
export const corporateApi = createApi({
  reducerPath: 'corporateApi',
  baseQuery: customBaseQuery,
  mode: 'cors',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  endpoints: (builder) => ({
    featuresGet: builder.query({
      query: () => '/product/featuresGet',
      providesTags: (result) => (result
        ? [
          featureListObject,
          ...result.map(
            ({ featureId }) => ({ type: FEATURE_TAG, id: featureId }),
          ),
        ]
        : [featureListObject]),
    }),
    featureGet: builder.query({
      query: ({ featureId }) => ({
        url: '/product/featureGet',
        params: {
          featureId,
        },
      }),
      providesTags: (
        result,
        error,
        { featureId },
      ) => [{ type: FEATURE_TAG, id: featureId }],
    }),
    featureUpsert: builder.mutation({
      query: (feature) => ({
        url: '/product/featureUpsert',
        method: 'PUT',
        body: feature,
      }),
      invalidatesTags: (result, error, { featureId }) => [
        { type: FEATURE_TAG, id: featureId },
        featureListObject,
      ],
    }),
    featureDelete: builder.mutation({
      query: (feature) => ({
        url: '/product/featureDelete',
        method: 'PUT',
        body: feature,
      }),
      invalidatesTags: (result, error, { featureId }) => [
        { type: FEATURE_TAG, id: featureId },
        featureListObject,
      ],
    }),
  }),
});

export const {
  useFeaturesGetQuery,
  useFeatureGetQuery,
  useFeatureUpsertMutation,
  useFeatureDeleteMutation,
} = corporateApi;

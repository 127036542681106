import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Identity, Table } from '@/components';
import { formatRangeDate } from '../helpers/dashboardHelpers';
import { ReactComponent as Logo } from '../../../../../../src/components/LogoSm.svg';
import DashboardUserChipAvatar from '../DashboardChipAvatar/DashboardUserChipAvatar';
import PermissionChips from '../PermissionChips';
import DashboardUserChipAvatarGroup from '../DashboardChipAvatar/DashboardUserChipAvatarGroup';
import styles from './DashboardTable.module.scss';
import useUserIdentity from '../hooks/useUserIdentity';
import MoreOptionsDashboard from '../MoreOptionsDashboard';

const DashboardTable = ({
  data,
  handleClickToRedirect,
  pageInfo,
  paginationTable,
  onPageChange,
  isLoadingPagination,
}) => {
  const columns = useMemo(() => [
    {
      accessorKey: 'thumbnail',
      header: 'View',
      Cell: ({ row }) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            className={styles.thumbnailBox}
            style={{ backgroundImage: `url(${row.original.thumbnail})` }}
          >
            {!data.thumbnail && <Logo className="logoSvgTable" />}
          </Box>
        </Box>
      ),
      size: 8,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ row }) => (
        <Typography variant="subtitle2" className={styles.titleText}>
          {row.original.title || `Untitled-${row.original.id}`}
        </Typography>
      ),
    },
    {
      accessorKey: 'summary',
      header: 'Summary',
      Cell: () => (
        <Typography variant="subtitle2" className={styles.summaryText}>
          No dashboard description.
        </Typography>
      ),
    },
    {
      accessorFn: (row) => row.history[0].effectiveRange,
      header: 'Created at',
      Cell: ({ row }) => {
        const createdAt = row.original.history[0].effectiveRange;
        return (
          <Typography variant="subtitle2" className={styles.createdAtText}>
            {formatRangeDate(createdAt)}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => row.history?.[row.history?.length - 1].effectiveRange,
      header: 'Updated at',
      Cell: ({ row }) => {
        const updatedAt = row.original.history?.[row.original.history?.length - 1].effectiveRange;
        return (
          <Typography variant="subtitle2" className={styles.updatedAtText}>
            {formatRangeDate(updatedAt)}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => row.history[row.history.length - 1],
      header: 'Last edited by',
      Cell: ({ row }) => {
        const lastHistoryItem = row.original.history[row.original.history.length - 1] || {};
        return (
          <Identity identityId={lastHistoryItem.identityId}>
            {({ identity }) => (
              <DashboardUserChipAvatar
                userId={identity.identityId}
                label={identity.firstName || identity.fullName}
                email={identity.loginEmailAddress}
                isShowLabel
              />
            )}
          </Identity>
        );
      },
    },
    {
      accessorKey: 'identities',
      header: 'Shared with',
      Cell: ({ row }) => {
        const userIdentity = useUserIdentity(row.original);
        return (
          <Box display="flex" flexDirection="row">
            <DashboardUserChipAvatarGroup
              data={
                row.original.identities
                  ?.filter((el) => el.supertokensIdentityExternalId !== userIdentity?.supertokensIdentityExternalId)
              }
              className={styles.avatarGroup}
            />
          </Box>
        );
      },
    },
    {
      accessorKey: 'permissionTagIds',
      header: 'Permissions',
      Cell: ({ row }) => (
        <PermissionChips permissions={row.original.permissionTagIds} />
      ),
    },
    {
      accessorKey: 'options',
      header: '',
      Cell: ({ row }) => {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <MoreOptionsDashboard dashboard={row.original} showFavorite />
          </Box>
        );
      },
      size: 10,
      enableSorting: false,
      enableColumnActions: false,
    },
  ], [handleClickToRedirect]);

  return (
    <Table
      columns={columns}
      data={data}
      enablePagination
      enableTopToolbar
      enableBottomToolbar
      onRowClick={handleClickToRedirect}
      pagination={paginationTable}
      onPaginationChange={onPageChange}
      isLoading={isLoadingPagination}
      rowCount={pageInfo.totalCount}
    />
  );
};

export default DashboardTable;

import clsx from 'clsx';
import React from 'react';
import { capitalize } from '@mui/material';
import { urnsToCSSClasses } from '@qloo/categories';
import { Button, EntityImage } from '@/components';
import { formatEntityCategories, getColorForEntity } from '@/utils';
import OmnisearchResultsTopAdditionalElement from './OmnisearchResultsTopAdditionalElement';
import styles from './Omnisearch.module.scss';

const OmnisearchResultsTopEntity = ({ entity, isSelected, itemProps }) => {
  const name = entity.name || '';
  const tags = entity.tags || [];
  const types = entity.types || [];

  const disambiguation = entity.disambiguation || '';
  const description = entity?.properties.description
    || '';

  return (
    <Button className={styles.topResultContainer} color="transparent" type="button" {...itemProps}>
      <div className={styles.infoContainer}>
        <div className={clsx({
          [styles.topResultImage]: true,
          [urnsToCSSClasses(types)]: true,
        })}
        >
          <EntityImage className={styles.topResultEntityImage} entity={entity} />
        </div>
        <div className={styles.topResultTopRight}>
          <div className={styles.topResultName}>{name}</div>
          {disambiguation ? (
            <div className={styles.topResultProperty}>
              {disambiguation}
            </div>
          ) : null}
          <div
            className={styles.topResultCategories}
            style={{ color: `hsl(var(--${getColorForEntity(entity)}-tag-border))` }}
          >
            {formatEntityCategories(entity)}
          </div>
        </div>
      </div>
      {description && <OmnisearchResultsTopAdditionalElement title="Description" content={description} />}
      {tags && tags.length > 0 && (
        <OmnisearchResultsTopAdditionalElement
          title="Tags"
          content={tags.map((tag) => capitalize(tag.name)).join(', ')}
        />
      )}
      <Button className={styles.topResultSelectButton} color="primary" variant="text">
        {isSelected ? 'Deselect' : 'Select'}
      </Button>
    </Button>
  );
};

export default OmnisearchResultsTopEntity;

import React from 'react';
import { urnsToDisplayName } from '@qloo/categories';
import { AffinityBar, EntityImage, EntityTrends } from '@/components';
import { filterTypes } from '@/constants';
import styles from '../DashboardPanel.module.scss';

export const affinityColumn = {
  accessorKey: 'query.affinity',
  header: 'Affinity',
  Cell: ({ renderedCellValue }) => <AffinityBar value={renderedCellValue} />,
  size: 110,
};

export const rankColumn = {
  accessorKey: 'rank',
  header: 'N°',
  Cell: ({ renderedCellValue, row }) => (
    <div className={styles.tableRank}>
      <EntityImage entity={row.original} />
      <div className={styles.tableRankNumber}>
        <div className={styles.tableRankNumberText}>
          {row.original.rank}
        </div>
      </div>
    </div>
  ),
  size: 40,
};

export const nameColumn = { accessorKey: 'name', header: 'Name' };
const popularityColumn = {
  accessorKey: 'popularity',
  header: 'Popularity',
  Cell: ({ row }) => (row.original.popularity === 1 ? 1 : parseFloat(row.original.popularity.toFixed(6))),
  size: 40,
};

const trendsColumn = {
  accessorFn: () => null,
  header: 'Trend: Percentile (6m avg)',
  Cell: ({ row }) => <EntityTrends key={row.original.entity_id} entityId={row.original.entity_id} />,
  size: 200,
};

const commonColumns = [
  rankColumn,
  nameColumn,
  affinityColumn,
  trendsColumn,
  popularityColumn,
];

const artistColumns = [
  ...commonColumns,
  { accessorKey: 'properties.date_of_birth', header: 'DOB', size: 25 },
  {
    accessorKey: 'properties.place_of_birth',
    header: 'Place of Birth',
    size: 25,
  },
];

const bookColumns = [
  ...commonColumns,
  { accessorKey: 'properties.publication_year', header: 'Year', size: 25 },
  { accessorKey: 'properties.page_count', header: 'Page Count' },
  { accessorKey: 'properties.language', header: 'Language', size: 25 },
  { accessorKey: 'properties.format', header: 'Format', size: 25 },
  { accessorKey: 'disambiguation', header: 'Disambiguation' },
];

const brandColumns = [
  ...commonColumns,
  { accessorKey: 'properties.headquartered', header: 'Headquarters', size: 25 },
  { accessorKey: 'properties.inception', header: 'Inception', size: 25 },
  {
    accessorKey: 'properties.official_site',
    header: 'Official Site',
    size: 25,
  },
  {
    accessorFn: (row) => {
      const products = row.properties.products || [];
      return products.join(', ');
    },
    header: 'Products',
    Cell: ({ cell }) => (
      <div className={styles.tableProducts}>
        {cell.getValue()}
      </div>
    ),
  },
];

const defaultColumns = [
  ...commonColumns,
  { accessorKey: 'disambiguation', header: 'Disambiguation' },
];

const destinationsColumns = [
  ...commonColumns,
  {
    accessorKey: 'properties.geocode.admin1_region',
    header: 'Region 1',
    size: 25,
  },
  {
    accessorKey: 'properties.geocode.admin2_region',
    header: 'Region 2',
    size: 25,
  },
  {
    accessorKey: 'properties.geocode.country_code',
    header: 'Country Code',
    size: 25,
  },
];

const peopleColumns = [
  ...commonColumns,
  { accessorKey: 'properties.citizenship', header: 'Citizenship', size: 25 },
  { accessorKey: 'properties.instrument', header: 'Instrument', size: 25 },
  { accessorKey: 'properties.lifestyle', header: 'Lifestyle', size: 25 },
  {
    accessorKey: 'properties.place_of_birth',
    header: 'Place of Birth',
    size: 25,
  },
  {
    accessorKey: 'properties.short_description',
    header: 'Short Description',
    size: 25,
  },
  { accessorKey: 'disambiguation', header: 'Disambiguation' },
];

const placeColumns = [
  ...commonColumns,
  { accessorKey: 'properties.address', header: 'Address', size: 25 },
  { accessorKey: 'properties.phone', header: 'Phone', size: 25 },
  {
    accessorKey: 'properties.business_rating',
    header: 'Business Rating',
    size: 25,
  },
  { accessorKey: 'properties.closed', header: 'Closed', size: 25 },
  { accessorKey: 'disambiguation', header: 'Disambiguation' },
];

const tvColumns = [
  ...commonColumns,
  {
    accessorKey: 'properties.content_rating',
    header: 'Content Rating',
    size: 25,
  },
  { accessorKey: 'properties.duration', header: 'Duration', size: 25 },
  { accessorKey: 'properties.release_date', header: 'Release Date', size: 25 },
  { accessorKey: 'properties.finale_year', header: 'Finale Year', size: 25 },
];

const videogameColumns = [
  rankColumn,
  nameColumn,
  {
    accessorKey: 'properties.console',
    header: 'Consoles',
    size: 25,
    Cell: ({ row }) => {
      const consoles = row.original.properties?.console || [];
      return consoles.join(', ');
    },
  },
  {
    accessorKey: 'properties.content_rating',
    header: 'Content Rating',
    size: 25,
  },
  { accessorKey: 'properties.publisher', header: 'Publisher', size: 25 },
  { accessorKey: 'properties.release_date', header: 'Release Date', size: 25 },
  { accessorKey: 'properties.developer', header: 'Developer', size: 25 },
];

const tagColumns = [
  nameColumn,
  affinityColumn,
  {
    header: 'Type',
    accessorFn: (row) => (row.type === 'urn:tag' ? 'Tags' : urnsToDisplayName(row.type) || row.type),
  },
];

const columnsMap = {
  [filterTypes.artist]: artistColumns,
  [filterTypes.book]: bookColumns,
  [filterTypes.brand]: brandColumns,
  [filterTypes.destination]: destinationsColumns,
  [filterTypes.person]: peopleColumns,
  [filterTypes.place]: placeColumns,
  [filterTypes.tvShow]: tvColumns,
  [filterTypes.videogame]: videogameColumns,
  [filterTypes.tag]: tagColumns,
};

const getColumns = (filterType) => columnsMap[filterType] || defaultColumns;

export default getColumns;

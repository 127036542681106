import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { useToggle } from '@/hooks';
import MapDrawShape from '../MapDrawShape';
import styles from './LocationPicker.module.scss';
import getDescribeLocation from '../../utils/getDescribeLocation';

const LocationPicker = ({
  value, color, onChange, onReset,
}) => {
  const isReadyToggle = useToggle();

  useEffect(() => {
    const timeout = setTimeout(isReadyToggle.on, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleChange = async (nextValue) => {
    const nextLocation = await getDescribeLocation(nextValue);
    onChange(nextLocation);
  };

  return (
    <FormControl component="fieldset" className={styles.formControl}>
      <FormGroup className={styles.formGroup}>
        {isReadyToggle.value ? (
          <MapDrawShape
            location={value}
            onChange={handleChange}
            disableControls={false}
            onReset={onReset}
            color={color}
          />
        ) : null}
      </FormGroup>
    </FormControl>
  );
};

export default LocationPicker;

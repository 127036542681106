import React, { useState } from 'react';

import { LocationPopover } from '@/components';
import LocationParam from '../LocationParam/LocationParam';

const PanelParamLocation = ({
  location, onChange, onReset,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <LocationParam
        color="blue"
        location={location}
        onClick={handleClick}
        placeholder="Select location"
      />
      <LocationPopover
        location={location}
        anchorEl={anchorEl}
        onClose={handleClose}
        onChange={onChange}
        onReset={onReset}
      />
    </div>
  );
};

export default PanelParamLocation;

import React from 'react';
import styles from './RangeInputs.module.scss';
import Input from '../Input';

const RangeInputs = ({ onChange, value }) => {
  const [valueMin, valueMax] = value || [null, null];

  const onChangeMin = (nextMin) => {
    onChange([nextMin, valueMax]);
  };

  const onChangeMax = (nextMax) => {
    onChange([valueMin, nextMax]);
  };

  return (
    <div className={styles.container}>
      <span>From</span>
      <Input onChange={onChangeMin} value={valueMin} />
      <span>To</span>
      <Input onChange={onChangeMax} value={valueMax} />
    </div>
  );
};

export default RangeInputs;

import RLSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import styles from './Skeleton.module.scss';

const Skeleton = ({ disabled, ...props }) => {
  return (
    <RLSkeleton {...props} enableAnimation={!disabled} className={styles.skeleton} />
  );
};

export default Skeleton;
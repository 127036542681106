import React, { useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, IconButton, Typography } from '@mui/material';
import { useToggle } from '@/hooks';
import { useUpdateIdentityDashboardIsFavoriteMutation } from '../../store/apis/dashboardsApi';

const FavoriteDashboard = ({
  dashboardId, identityId, isFavorite = false, title,
}) => {
  const [updateIdentityDashboardIsFavorite] = useUpdateIdentityDashboardIsFavoriteMutation();
  const isFavoriteToggle = useToggle(isFavorite);

  useEffect(() => {
    isFavoriteToggle.set(isFavorite);
  }, [isFavorite, isFavoriteToggle]);

  const handleClickFavorite = (event) => {
    event.stopPropagation();
    const nextIsFavorite = !isFavoriteToggle.value;
    isFavoriteToggle.set(nextIsFavorite);
    updateIdentityDashboardIsFavorite({ dashboardId, identityId, isFavorite: nextIsFavorite });
  };

  return (
    <Box className="container-item-more-options" onClick={handleClickFavorite}>
      <IconButton aria-label="add to favorites" sx={{ padding: 0 }}>
        {isFavoriteToggle.value
          ? <StarIcon sx={{ color: 'hsl(var(--yellow))' }} />
          : <StarBorderIcon />}
      </IconButton>

      {title && (
        <Typography variant="subtitle2">
          {title}
        </Typography>
      )}
    </Box>
  );
};

export default FavoriteDashboard;

import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAudienceBatchedRequestsContext } from '@/contexts/AudienceBatchedRequestContext';
import { fetchAudienceStart } from '@/store/slices/audiencesSlice';

const AudienceText = ({ audienceId, className, index }) => {
  const audienceBatchedRequests = useAudienceBatchedRequestsContext();

  const dispatch = useDispatch();

  const audience = useSelector((state) => state.audiences.byId[audienceId]);

  const fetchIfNeeded = () => {
    if (!audience) {
      dispatch(fetchAudienceStart(audienceId));
      audienceBatchedRequests.queueArg(audienceId);
    }
  };

  useEffect(() => {
    fetchIfNeeded();
  }, []);

  return (
    <>
      {typeof index === 'number' && index > 0 ? <span>{', '}</span> : null}
      <span className={clsx({ [className]: className })}>{audience?.name || `${audienceId.slice(0, 11)}...`}</span>
    </>
  );
};

export default AudienceText;

import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useErrorBoundary } from 'react-error-boundary';
import { getDashboardCountAndFirstId } from '@/utils';
import {
  useGetDashboardDetailsQuery,
  useGetDashboardRecentQuery,
  useGetDashboardSearchQuery,
  useGetDashboardsQuery,
} from '@/store/apis/dashboardsApi';
import { ErrorBoundary } from '@/components';
import { TYPES_FILTERS } from './consts';
import { useDebounce } from '@/hooks';

import { USER_ROLES } from '@/constants/roles';
import { getFilteredDashboards } from './helpers/dashboardHelpers';
import DashboardSection from './DashboardSection';
import DashboardSearchInput from './DashboardSearchInput';
import SortButton from './SortButton';
import DashboardTable from './DashboardTable/DashboardTable';
import DashboardCreateButton from '../Dashboard/DashboardTabs/DashboardCreateButton';
import DashboardHomeSkeleton from './Skeleton/DashboardHomeSkeleton';
import DashboardPageErrorFallback from '../Dashboard/DashboardPageErrorFallback';
import './style.scss';
import Alert from '@/components/Alert';
import PermissionsValidatorWrapper from '@/components/PermissionsValidatorWrapper';
import { DashboardRole } from '@/constants/permissions/dashboard';
import DashboardOpenTabs from '@/screens/HomePage/DashboardOpenTabs';

const DashboardHomeWithBoundary = (props) => {
  return (
    <ErrorBoundary FallbackComponent={DashboardPageErrorFallback}>
      <HomePage {...props} />
    </ErrorBoundary>
  );
};

const getOwnershipPermissions = (ownershipFilter) => {
  switch (ownershipFilter) {
    case TYPES_FILTERS.OWNED_BY_ME:
      return {
        permissionTagIds: [USER_ROLES.ADMIN, USER_ROLES.CREATOR],
        excludePermissions: [],
      };
    case TYPES_FILTERS.NOT_OWNED_BY_ME:
      return {
        permissionTagIds: [USER_ROLES.VIEWER, USER_ROLES.COMMENTER, USER_ROLES.DELETER, USER_ROLES.EDITOR],
        excludePermissions: [USER_ROLES.ADMIN, USER_ROLES.CREATOR, USER_ROLES.OWNER],
      };
    case TYPES_FILTERS.OWNED_BY_ANYONE:
    case TYPES_FILTERS.MY_FAVORITES:
    default:
      return {
        permissionTagIds: [USER_ROLES.ADMIN, USER_ROLES.VIEWER, USER_ROLES.CREATOR, USER_ROLES.OWNER],
        excludePermissions: [],
      };
  }
};

const filterFavorites = (dashboards, userId) => {
  return Object.values(dashboards).filter((dashboard) => dashboard.identities?.some((identity) => identity.supertokensIdentityExternalId === userId && identity.isFavorite));
};

const HomePage = () => {
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  const { user } = useSelector((state) => state.app);
  const {
    dashboardsRecents,
    dashboardsDetails: dashboards,
    items,
    dashboardsPageInfo,
  } = useSelector((state) => state.dashboards);
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);
  const dashboardRecentsData = Object.values(dashboardsRecents);
  const dashboardData = Object.values(dashboards);
  const { count: openDashboardCount, firstId } = getDashboardCountAndFirstId(dashboardIds, items);
  const [ownershipFilter, setOwnershipFilter] = useState(TYPES_FILTERS.OWNED_BY_ANYONE);
  const [valueFilter, setValueFilter] = useState('');
  const [paginationTable, setPaginationTable] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [paginationSearchTable, setPaginationSearchTable] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isSearching, setIsSearching] = useState(false);
  const [errorWhenCreatingDashboard, setErrorWhenCreatingDashboard] = useState(false);

  const debouncedSearch = useDebounce(valueFilter, 500);

  const { permissionTagIds, excludePermissions } = useMemo(() => getOwnershipPermissions(ownershipFilter), [ownershipFilter]);

  const { isLoading: isLoadingDashboardData, refetch: refetchDashboardData, error: errorDashboardDetails } = useGetDashboardDetailsQuery({
    offset: paginationTable.pageIndex,
    limit: paginationTable.pageSize,
    permissionTagIds,
    excludePermissions,
  });
  useGetDashboardsQuery();

  const { data: searchResultsData, isLoading: isLoadingSearchResults, error: errorSearch } = useGetDashboardSearchQuery({
    query: debouncedSearch,
    offset: paginationSearchTable.pageIndex,
    limit: paginationSearchTable.pageSize,
  }, { skip: !isSearching });

  const { isLoading: isLoadingDashboardRecentData } = useGetDashboardRecentQuery({});

  useEffect(() => {
    if (errorSearch) {
      showBoundary(errorSearch?.data);
    }
  }, [errorSearch]);

  useEffect(() => {
    if (!errorDashboardDetails) {
      refetchDashboardData();
    }
  }, [ownershipFilter, permissionTagIds, excludePermissions, paginationTable]);

  useEffect(() => {
    if (debouncedSearch) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
      refetchDashboardData();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (errorWhenCreatingDashboard) {
      setTimeout(() => {
        setErrorWhenCreatingDashboard(false);
      }, 3000);
    }
  }, [errorWhenCreatingDashboard]);

  const handleClickToRedirect = (id) => {
    navigate(`/dashboards/view${id ? `/${id}` : ''}/`);
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setValueFilter(value);
  };

  const handleOwnershipSelect = (option) => {
    setOwnershipFilter(option);
  };

  const tableData = isSearching ? searchResultsData?.data : (ownershipFilter === TYPES_FILTERS.MY_FAVORITES ? filterFavorites(dashboardData, user.userId) : dashboardData);

  const filteredDashboards = useMemo(() => (
    getFilteredDashboards(tableData, debouncedSearch)
  ), [tableData, debouncedSearch]);

  if (isLoadingDashboardData || isLoadingDashboardRecentData) {
    return <DashboardHomeSkeleton />;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      {errorWhenCreatingDashboard && (
        <Alert
          severity="error"
          message="An error occurred when trying to create a dashboard."
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        gap={4}
        padding={3}
        pl={5}
        pr={5}
      >
        <Typography variant="h6" fontWeight={600}>Dashboards</Typography>
        <DashboardSearchInput
          onChange={handleFilterChange}
          value={valueFilter}
          disabled={
            !isSearching
            && valueFilter?.length > 0
            && dashboardData?.length === 0
            && dashboardRecentsData?.length === 0
          }
        />
        <PermissionsValidatorWrapper
          component={DashboardCreateButton}
          permission={DashboardRole.CREATE}
          additionalProps={{ setCreateError: setErrorWhenCreatingDashboard }}
        />
        <PermissionsValidatorWrapper
          component={DashboardOpenTabs}
          permission={DashboardRole.VIEW}
          additionalProps={{ onClick: () => handleClickToRedirect(firstId), openDashboardCount }}
        />
      </Box>
      {(!valueFilter || getFilteredDashboards(dashboardRecentsData, valueFilter).length > 0) && (
        <Box className="dashboard-section">
          <PermissionsValidatorWrapper
            component={DashboardSection}
            permission={DashboardRole.CREATE}
            additionalProps={{
              title: 'Recent',
              data: getFilteredDashboards(dashboardRecentsData, valueFilter),
              handleClickToRedirect,
              valueFilter,
            }}
          />
        </Box>
      )}
      <Box width="100%" padding={3} display="flex" flexDirection="column" pl={5} pr={5} mt={2}>
        <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
          <SortButton selectedOption={ownershipFilter} onSelect={handleOwnershipSelect} />
        </Box>
        <Box className="custom-box-table">
          <DashboardTable
            data={filteredDashboards}
            handleClickToRedirect={handleClickToRedirect}
            pageInfo={dashboardsPageInfo}
            paginationTable={debouncedSearch ? paginationSearchTable : paginationTable}
            isLoadingPagination={isLoadingDashboardData || (isLoadingSearchResults && !errorSearch)}
            onPageChange={debouncedSearch ? setPaginationSearchTable : setPaginationTable}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardHomeWithBoundary;

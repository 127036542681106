import React, { useState } from 'react';
import { Box, IconButton, Popover } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import clsx from 'clsx';
import useScrollBlock from '@/hooks/useScrollBlock';
import { USER_ROLES } from '@/constants/roles';
import { useGetDashboardQuery } from '@/store/apis/dashboardsApi';
import useUserDashboardIdentity from '@/screens/HomePage/hooks/useUserDashboardIdentity';
import DashboardRename from './DashboardRename';
import ShareWith from './ShareWith/ShareWith';
import DashboardDelete from './DashboardDelete/DashboardDelete';
import FavoriteDashboard from './FavoriteDashboard';
import DashboardClone from '../Dashboard/DashboardClone/DashboardClone';

const MoreOptionsDashboard = ({ dashboard, showFavorite, handleConfirmDeleteAdditional }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [blockScroll, allowScroll] = useScrollBlock();

  useGetDashboardQuery({ id: dashboard?.id });

  const userDashboardIdentity = useUserDashboardIdentity(dashboard.id);

  const handleMoreClick = (event) => {
    event.stopPropagation();
    blockScroll();
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    allowScroll();
    setAnchorEl(null);
  };

  // TODO: Check this
  const handleConfirmDeleteInternal = () => {
    if (handleConfirmDeleteAdditional) {
      handleConfirmDeleteAdditional();
    }
    handleMoreClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-label="settings" onClick={handleMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMoreClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box className="popoverBox">
          {showFavorite
            && (
              <Box className="popoverItem">
                <FavoriteDashboard
                  dashboardId={dashboard.dashboardId}
                  title="Favorite"
                  identityId={userDashboardIdentity?.identityId}
                  isFavorite={userDashboardIdentity?.isFavorite}
                />
              </Box>
            )}
          <Box className={clsx({
            popoverItem: true,
            popoverDisabled: !userDashboardIdentity?.grantedPermissionTagIds ? false
              : !(userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.ADMIN)
                || userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.OWNER)),
          })}
          >
            <ShareWith
              title="Share"
              dashboard={dashboard}
              disabled={
                !userDashboardIdentity?.grantedPermissionTagIds ? false
                  : !(userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.ADMIN)
                    || userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.OWNER))
              }
            />
          </Box>
          <Box className={clsx({
            popoverItem: true,
            popoverDisabled: !userDashboardIdentity?.grantedPermissionTagIds ? false
              : !userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.EDITOR),
          })}
          >
            <DashboardRename
              title="Rename"
              dashboard={dashboard}
              disabled={!userDashboardIdentity?.grantedPermissionTagIds ? false
                : !userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.EDITOR)}
              onClose={handleMoreClose}
            />
          </Box>
          <Box className={clsx({
            popoverItem: true,
            popoverDisabled: !userDashboardIdentity?.grantedPermissionTagIds ? false
              : !userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.CLONE),
          })}
          >
            <DashboardClone
              dashboard={dashboard}
              label="Clone"
              onConfirmAdditional={handleConfirmDeleteInternal}
              disabled={!userDashboardIdentity?.grantedPermissionTagIds ? false
                : !userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.CLONE)}
            />
          </Box>
          <Box className={clsx({
            popoverItem: true,
            popoverDisabled: !userDashboardIdentity?.grantedPermissionTagIds ? false
              : !userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.DELETER),
          })}
          >
            <DashboardDelete
              dashboard={dashboard}
              label="Delete"
              onConfirmAdditional={handleConfirmDeleteInternal}
              disabled={!userDashboardIdentity?.grantedPermissionTagIds ? false
                : !userDashboardIdentity?.grantedPermissionTagIds.includes(USER_ROLES.DELETER)}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default MoreOptionsDashboard;

import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback, useState } from 'react';
import { Plus } from 'react-feather';
import { Popover } from '@/components';
import { useOwnership } from '../../hooks';
import { useDashboardActionsContext } from '../../DashboardActionsProvider';
import QueryBuilder from '../../QueryBuilder';
import QueryBuilderPopover from '../../QueryBuilderPopover';
import ModifierItem from './ModifierItem';
import ModifierPanelItem from './ModifierPanelItem';

import { POPOVERS } from '../../helpers/constants';
import styles from './PanelParamsModifiers.module.scss';

const PanelParamsModifiers = ({ baseParams, panelId, panelParams }) => {
  const dashboardActions = useDashboardActionsContext();

  // Ownership
  const tag = `${panelId}-header`;
  const headerOwnership = useOwnership(tag);

  // Popover
  const [openPopover, setOpenPopover] = useState(null);
  const modifiersButtonPopupState = usePopupState({
    variant: 'popper',
    popupId: 'modifiersButtonPopupState',
  });

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'demoPopper',
  });

  const handleChange = ({ key, value }) => {
    dashboardActions.changePanelParams({ panelId, nextParams: { [key]: value } });
  };

  const handleClose = () => {
    setOpenPopover(null);
    modifiersButtonPopupState.close();
    popupState.close();
  };

  const handleOpen = useCallback((popover) => (e) => {
    setOpenPopover(popover);
    popupState.open(e);
  }, [popupState]);

  const handleReset = (paramNames) => {
    dashboardActions.deletePanelParams({ panelId, paramNames });
  };

  return (
    <div className={styles.container}>
      <QueryBuilder onOpen={handleOpen} params={panelParams} veltLocation="">
        {({
          hasDemographics,
          hasInterests,
          hasLocation,
          demographicsLabel,
          interestsLabel,
          locationLabel,
          openDemographicsPopover,
          openInterestsPopover,
          openLocationPopover,
        }) => (
          <div className={styles.baseQuery}>
            <div className={styles.popoverWrapper}>
              <Popover
                className={styles.modifierButton}
                color="accent"
                popupState={modifiersButtonPopupState}
                renderContent={() => (
                  <div>
                    <ModifierPanelItem
                      color="orange"
                      label="Demographics"
                      onClick={handleOpen(POPOVERS.demographics)}
                    />
                    <ModifierPanelItem
                      color="purple"
                      label="Location"
                      onClick={handleOpen(POPOVERS.locations)}
                    />
                    <ModifierPanelItem
                      color="blue"
                      label="Interests"
                      onClick={handleOpen(POPOVERS.interests)}
                    />
                  </div>
                )}
                renderTrigger={() => (
                  <span className={styles.modifierButtonLabel}><Plus size={13} /><span>Modifier</span></span>
                )}
              />
            </div>

            {!!hasDemographics && (
              <ModifierItem
                color="orange"
                label={demographicsLabel}
                onClick={openDemographicsPopover}
              />
            )}
            {!!hasLocation && (
              <ModifierItem
                color="purple"
                label={locationLabel}
                onClick={openLocationPopover}
              />
            )}
            {!!hasInterests && (
              <ModifierItem
                color="blue"
                label={interestsLabel}
                onClick={openInterestsPopover}
              />
            )}
          </div>
        )}
      </QueryBuilder>
      <QueryBuilderPopover
        baseParams={baseParams}
        lock={headerOwnership.set}
        popupState={popupState}
        onChange={handleChange}
        onClose={handleClose}
        onReset={handleReset}
        openPopover={openPopover}
        params={panelParams}
        releaseLock={headerOwnership.clear}
      />
    </div>
  );
};

export default PanelParamsModifiers;

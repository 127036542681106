import { Button } from "@/components";
import clsx from "clsx";
import { Modal as MuiModal } from "@mui/base/Modal";
import { forwardRef } from "react";
import { X } from "react-feather";
import styles from "./Modal.module.scss";

const Modal = ({ className, close, isOpen, renderContent, title }) => {
  return (
    <MuiModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      className={styles.container}
      open={isOpen}
      onClose={close}
      slots={{
        backdrop: forwardRef(() => <div className={styles.backdrop} />),
      }}
    >
      <div className={clsx({ [styles.content]: true, [className]: true })}>
        <div
          className={clsx({
            [styles.header]: true,
            [styles.headerBordered]: title,
          })}
        >
          {title ? <h2 className={styles.title}>{title}</h2> : null}
          {!!close && (
            <Button onClick={close} size="sm" variant="ghost"> {/* TODO: Check is params are right */}
              <X />
            </Button>
          )}
        </div>
        {isOpen ? renderContent() : null}
      </div>
    </MuiModal>
  );
};
export default Modal;

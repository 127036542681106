import Tune from '@mui/icons-material/Tune';
import React, { useMemo } from 'react';
import { FieldsModal, Popover } from '@/components';
import { paramNames } from '@/constants';
import FilterTag from './FilterTag';

import getFilterParamNames from '../helpers/getFilterParamNames';
import { getFiltersApplied } from './helpers';
import styles from './FilterTag.module.scss';

const AddFilterLabel = () => (
  <div className={styles.addFilterLabel}>
    <Tune fontSize="small" />
  </div>
);

const FilterTagsContainer = ({
  params, onParamsChange, onParamDelete, onReset,
}) => {
  const filterParamNames = useMemo(() => getFilterParamNames(params[paramNames.filterType]), [params]);
  const filtersApplied = useMemo(() => getFiltersApplied(params, filterParamNames), [filterParamNames, params]);

  const handleReset = (close) => {
    onReset();
    close();
  };

  return (
    <div className={styles.tagsContainer}>
      <div className={styles.tagsWrapper}>
        {Object.entries(filtersApplied).map(([paramName, content]) => (
          <FilterTag key={paramName} paramName={paramName} content={content} onDelete={onParamDelete} />
        ))}
      </div>
      <div className={styles.tagsFilterButtonWrapper}>
        <Popover
          color="accent"
          variant="contained"
          size="small"
          className={styles.addFilterTag}
          renderContent={({ close }) => (
            <FieldsModal
              onChange={onParamsChange}
              onReset={() => handleReset(close)}
              params={params}
              paramNames={filterParamNames}
              title="Filters"
            />
          )}
          renderTrigger={() => <AddFilterLabel />}
        />

      </div>
    </div>
  );
};

export default FilterTagsContainer;

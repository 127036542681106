import React, { useEffect, useMemo, useState } from 'react';
import { Source, Layer } from '@urbica/react-map-gl';
import Map from '../Map';
import HeatmapRangeSlider from './HeatmapRangeSlider';
import HeatmapOpacitySlider from './HeatmapOpacitySlider';
import HeatmapToggle from './HeatmapToggle';

import styles from './MapHeatmap.module.scss';

const heatmapToGeojson = (heatmapData) => ({
  type: 'FeatureCollection',
  features: Array.isArray(heatmapData) ? heatmapData.map(({ lat, lon, query }) => ({
    type: 'Feature',
    properties: {
      ...query,
      // eslint-disable-next-line max-len
      // weighted: (query?.affinity_raw && query?.popularity) && (query.affinity_raw * 0.8) + (query.popularity * 0.3),
      affinity: query.affinity * 0.5,
      affinity_rank: query?.affinity_rank,
    },
    geometry: {
      type: 'Point',
      coordinates: [lon, lat, 1],
    },
  })) : [],
});

const MapHeatmap = ({ data, controls, onControlsChange }) => {
  const [heatmapType, setHeatmapType] = useState(controls?.heatmapType || 'affinity');
  const [heatmapRange, setHeatmapRange] = useState(controls?.heatmapRange || 19);
  const [heatmapOpacity, setHeatmapOpacity] = useState(controls?.heatmapOpacity || 0.6);

  const geoJson = useMemo(() => heatmapToGeojson(data), [data]);

  //Debounce
  useEffect(() => {
    if (heatmapType || heatmapRange || heatmapOpacity) {
      const handler = setTimeout(() => {
        onControlsChange({ heatmapType, heatmapRange, heatmapOpacity });
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [heatmapType, heatmapRange, heatmapOpacity]);

  useEffect(() => {
    if (controls) {
      setHeatmapType(controls?.heatmapType);
      setHeatmapRange(controls?.heatmapRange);
      setHeatmapOpacity(controls?.heatmapOpacity);
    }
  }, [controls]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeatmapToggle value={heatmapType} onChange={setHeatmapType} />
        <HeatmapRangeSlider value={heatmapRange} onChange={setHeatmapRange} />
        <HeatmapOpacitySlider value={heatmapOpacity} onChange={setHeatmapOpacity} />
      </div>
      <Map
        followNewFeatures
        features={geoJson}
        defaultZoom={6}
      >
        <Source
          id="heatmapsource"
          type="geojson"
          data={geoJson}
        />
        <Layer
          id="heatmap"
          type="heatmap"
          source="heatmapsource"
          paint={{
            // Increase the heatmap weight based on frequency and property magnitude
            'heatmap-weight': [
              'interpolate',
              ['linear'],
              ['get', heatmapType],
              0, 0,
              7, 1,
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            'heatmap-intensity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              // zoom is 5 (or less) -> intensity 1
              0, 1,
              // zoom is 10 (or greater) -> intensity 3
              20, 5,
            ],
            // Adjust the heatmap radius by zoom level
            'heatmap-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              // zoom <= 1  = 1px
              1, 1,
              // zoom <= 10  = 20px
              10, heatmapRange,
              // zoom >= 20  = 100px
              20, 344,
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(33,102,172,0)',
              0.2,
              'rgb(103,169,207)',
              0.4,
              'rgb(209,229,240)',
              0.6,
              'rgb(253,219,199)',
              0.8,
              'rgb(239,138,98)',
              1,
              'rgb(178,24,43)',
            ],
            // Transition from heatmap to circle layer by zoom level
            'heatmap-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7, heatmapOpacity,
              9, heatmapOpacity,
            ],
          }}
        />
      </Map>
    </div>
  )
};

export default MapHeatmap;
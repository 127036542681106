import React from 'react';
import { Skeleton } from '@/components';
import styles from './DashboardTabs.module.scss';
import DashboardTabsAddButtonSkeleton from '@/screens/Dashboard/DashboardTabs/DashboardTabsAddButtonSkeleton';
import DashboardGridLinkSkeleton from '@/screens/Dashboard/DashboardTabs/DashboardGridLinkSkeleton';

const DashboardTabsSkeleton = () => (
  <div className={styles.container}>
    <DashboardGridLinkSkeleton />
    <DashboardTabsAddButtonSkeleton />

    <div className={`${styles.tab} ${styles.tabActive}`}>
      <Skeleton width={130} />
    </div>
    <div className={styles.tab}>
      <Skeleton width={130} />
    </div>
    <div className={styles.tab}>
      <Skeleton width={130} />
    </div>
  </div>
);

export default DashboardTabsSkeleton;

import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToggle } from '@/hooks';

import DashboardTabsHomeButton from './DashboardTabsHomeButton';
import DashboardTabsAddButton from './DashboardTabsAddButton';
import DashboardTab from './DashboardTab';
import DashboardTabsSkeleton from './DashboardTabsSkeleton';

import styles from './DashboardTabs.module.scss';

const DashboardTabsWrapper = ({ isLoading }) => {
  const isReadyToggle = useToggle();
  useEffect(() => {
    isReadyToggle.on();
  }, []);

  if (isLoading) {
    return <DashboardTabsSkeleton />;
  }

  if (isReadyToggle.value) {
    return <DashboardTabs />;
  }

  return null;
};

const DashboardTabs = () => {
  const params = useParams();
  const id = Number(params.id);
  const { items } = useSelector((state) => state.dashboards);
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);

  const dashboardsForTabs = useMemo(() => {
    return dashboardIds.reduce((acc, dashboardId, i) => {
      const dashboard = items[dashboardId];
      if (dashboard) {
        return [
          ...acc,
          {
            dashboard,
            dashboardId,
            index: i,
          },
        ];
      }

      return acc;
    }, []);
  }, [items, dashboardIds]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.buttons}>
          <DashboardTabsHomeButton />
          <DashboardTabsAddButton />
        </div>
        {dashboardsForTabs.map(({ dashboard, dashboardId, index }) => (
          <DashboardTab
            key={dashboardId}
            dashboard={dashboard}
            dashboardIds={dashboardIds}
            id={dashboardId}
            index={index}
            isSelected={dashboardId === id}
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardTabsWrapper;

import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import MapGL, {
  FullscreenControl, NavigationControl, GeolocateControl, MapContext,
} from '@urbica/react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import bbox from '@turf/bbox';
import { usePrevious, useScrollBlock } from '@/hooks';
// import usePermission from '../lib/usePermissionsBase';
import useTheme from '@/hooks/useTheme';

const SetViewportProvider = React.createContext([{}, () => {
  console.error('This call was made outside of the provider');
}]);
export const useViewportProvider = () => useContext(SetViewportProvider);

const useCompare = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

const DEFAULT_LATITUDE = 40.742306;
const DEFAULT_LONGITUDE = -74.003494;

export const MapProvider = ({ defaultZoom = 4, children }) => {
  const viewportGetSet = useState({
    latitude: DEFAULT_LATITUDE,
    longitude: DEFAULT_LONGITUDE,
    zoom: defaultZoom,
  });

  return (
    <SetViewportProvider.Provider value={viewportGetSet}>{children}</SetViewportProvider.Provider>
  );
};

export const MapDisplay = ({
  style = { width: '100%', flex: 1 },
  children = [],
  latitude,
  longitude,
  followNewFeatures = false,
  features,
  ...props
}) => {
  const { isDarkMode } = useTheme();
  const [viewport, setViewport] = useViewportProvider();
  const canShowMapControls = true;
  const canShowMapInteractions = true;
  // const [canShowMapControls, canShowMapInteractions] = usePermission(
  //   'feature.request-browser.allControls',
  //   'feature.request-browser.mapControls',
  //   'feature.request-browser.mapInteractions',
  // );

  const [map, setMap] = useState();
  const [mapWasInit, setMapWasInit] = useState(false);
  const featuresChanged = useCompare(features);
  const [blockScroll, allowScroll] = useScrollBlock();

  const mapStyle = useMemo(() => (isDarkMode ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/light-v11'), [isDarkMode]);

  useEffect(() => {
    if ((mapWasInit === false || (followNewFeatures && featuresChanged)) && map && features) {
      let bounds;
      try {
        bounds = bbox(features);
      // eslint-disable-next-line no-empty
      } catch (e) {
        console.error(e);
      }
      if (parseFloat(bounds?.[0]) !== Infinity) {
        try {
          map.fitBounds(bounds, { padding: 40 });
        } catch (error) {
          // Case: Map canvas is tiny
          map.fitBounds(bounds);
        }
        setMapWasInit(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features, map]);

  const handleHoverEnter = () => {
    blockScroll();
  };

  const handleHoverLeave = () => {
    if (!document.fullscreenElement) {
      allowScroll();
    }
  };

  const handleMapLoad = () => {
    const attachListenersToFullscreenButtons = () => {
      const fullscreenButtons = document.querySelectorAll('.mapboxgl-ctrl-fullscreen');
      fullscreenButtons.forEach((button) => {
        button.removeEventListener('mouseenter', handleHoverEnter);
        button.removeEventListener('mouseleave', handleHoverLeave);
        button.addEventListener('mouseenter', handleHoverEnter);
        button.addEventListener('mouseleave', handleHoverLeave);
      });
    };

    const observer = new MutationObserver(() => {
      attachListenersToFullscreenButtons();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    attachListenersToFullscreenButtons();

    return () => {
      observer.disconnect();
    };
  };

  return (
    <MapGL
      {...props}
      style={style}
      mapStyle={mapStyle}
      accessToken={import.meta.env.VITE_MAPBOX_KEY}
      latitude={latitude || viewport.latitude}
      longitude={longitude || viewport.longitude}
      zoom={viewport.zoom}
      attributionControl={false}
      onViewportChange={canShowMapInteractions && setViewport}
      viewportChangeMethod="flyTo"
      onLoad={handleMapLoad}
    >
      {children}
      <MapContext.Consumer>
        {(_map) => {
          // Forgive me lord
          if (_map !== map) { setMap(_map); }
        }}
      </MapContext.Consumer>
      {
        canShowMapControls && (
          <>
            <NavigationControl showCompass showZoom position="top-right" />
            <FullscreenControl position="top-right" />
            <GeolocateControl position="bottom-right" />
          </>
        )
      }
    </MapGL>
  );
};

export default (props) => <MapProvider {...props}><MapDisplay {...props} /></MapProvider>;

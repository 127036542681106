import { Grid } from '@mui/material';
import Tag from '@/components/Tag';
import { getColorForTag } from '@/utils';


/**
 * Renders a list of tags.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {Object} getItemsProps - Props passed to the tags list to make it behave like an item
 * @param {Object} tagsStartIndex - Start index based on top result
 * @param {Boolean} showMoreTags - Boolean prop to show more or less tags
 * @param {Boolean} showSelectedIcon - Boolean prop to define show or not the selected icon in tag
 * @param {Object} rest - Rest of properties to add item behavior to tags
 * @param {Array<Object>} props.tags - An array of tag objects to display.
 * @returns {JSX.Element} The rendered list of tags.
 */
const TagsList = ({
  tags, valueBitmap, getItemProps, tagsStartIndex, showMoreTags, showSelectedIcon, category, useCategoryColor,
}) => {
  // const colorEnhancedTags = enhanceListWithColors(tags);
  return (
    <Grid container spacing={1} sx={{ alignContent: 'flex-start' }}>
      {(showMoreTags ? tags : tags.slice(0, 16))?.map((tag, index) => {
        const isSelected = valueBitmap ? valueBitmap[tag.entity_id || tag.id] : false;

        const color = getColorForTag(tag);
        return (
          <Grid sx={{ padding: 0 }} item key={`grid_wrapper_${tag.entity_id || tag.id}`}>
            <Tag
              key={tag.tagId}
              color={color}
              isSelected={isSelected}
              showSelectedIcon={showSelectedIcon}
              category={category}
              useCategoryColor={useCategoryColor}
              {...tag}
              {...(getItemProps ? getItemProps({
                index: tagsStartIndex + index,
                item: tag.entity_id || tag.id,
              }) : null)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TagsList;

import { Disc } from 'react-feather';
import { Box } from '@mui/system';
// import Select from '../Select/Select';
import { Slider } from '@mui/material';

import styles from './MapDrawShape.module.scss';
import Input from '../Input';

const RadiusSelector = ({ radius, onChange }) => {
  return (
    <Box display="flex" alignItems="center" gap="8px" paddingLeft="16px" minWidth={250}>
      <Disc size={64} />
      <span>Radius:</span>
      <Slider
        value={radius}
        onChange={(e) => onChange(e.target.value)}
        className={styles.radiusSlider}
        min={1}
        max={800000}
      />
      <Input
        value={radius}
        onChange={onChange}
        type="number"
        size="small"
        className={styles.radiusInput}
      />
    </Box>
  );
};

export default RadiusSelector;

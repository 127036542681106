// TODO: Fix dependency cycles
/* eslint import/no-cycle: 0 */

import React, { useEffect, useMemo, useState } from 'react';
import TagsList from '@/components/TagsList';
import OmnisearchResultsTop from './OmnisearchResultsTop';
import styles from './Omnisearch.module.scss';
import Button from '@/components/Button';
import EntitiesList from '@/components/Omnisearch/EntitiesList';

const OmnisearchResults = ({
  getItemProps, getMenuProps, omnisearchResults, value,
}) => {
  const {
    topResult, entities, isEmpty, isLoading, tags,
  } = omnisearchResults;

  const [showMoreTags, setShowMoreTags] = useState(false);

  const valueBitmap = useMemo(
    () => value.reduce((obj, item) => ({ ...obj, [item]: 1 }), {}),
    [value],
  );

  const tagsStartIndex = topResult ? 1 : 0;
  const entitiesStartIndex = tagsStartIndex + tags.length;

  useEffect(() => {
    if (!isLoading) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isLoading, topResult, entities, tags]);

  const handleToggleShowMoreTags = () => {
    setShowMoreTags(!showMoreTags);
  };

  return (
    <div className={styles.results} {...getMenuProps(true)}>
      {!isLoading && isEmpty ? <div>No Results</div> : null}
      {topResult || tags.length ? (
        <div className={styles.resultsTop}>
          {topResult ? (
            <OmnisearchResultsTop
              getItemProps={getItemProps}
              isSelected={valueBitmap[topResult.entity_id || topResult.id]}
              topResult={topResult}
            />
          ) : null}
          {tags.length ? (
            <div className={styles.tagsContainer}>
              <div className={styles.tags}>
                <TagsList
                  tags={tags}
                  getItemProps={getItemProps}
                  valueBitmap={valueBitmap}
                  tagsStartIndex={tagsStartIndex}
                  showMoreTags={showMoreTags}
                  useCategoryColor={false}
                />
              </div>
              {tags.length > 16 && (
                <Button
                  variant="text"
                  color="primary"
                  className={styles.showMoreTagsButton}
                  onClick={handleToggleShowMoreTags}
                >
                  {showMoreTags ? 'Show less' : 'Show more'}
                </Button>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      {entities.length ? (
        <EntitiesList
          entities={entities}
          entitiesStartIndex={entitiesStartIndex}
          getItemProps={getItemProps}
          valueBitmap={valueBitmap}
        />
      ) : null}
    </div>
  );
};

export default OmnisearchResults;

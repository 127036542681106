import { getReasonPhrase } from 'http-status-codes';

const extractErrorMessage = (error) => {
  if (!error) return 'Unknown error occurred';

  if (error?.message) {
    return error.message;
  }

  if (error?.status) {
    return `Error ${error.status} - ${getReasonPhrase(error.status)}`;
  }

  try {
    if (error?.toString) {
      return error.toString();
    }
  } catch (err) {
    return 'Unknown error occurred';
  }

  return 'Unknown error occurred';
};

export default extractErrorMessage;

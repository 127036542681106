import React, { useEffect, useMemo } from 'react';
import { urnsToDisplayName } from '@qloo/categories';
import { ArrowForwardIos } from '@mui/icons-material';
import { useCategoryPerms } from '@v1/lib/usePermissions';
import { useErrorBoundary } from 'react-error-boundary';
import styles from './PanelBodyExplore.module.scss';
import { Button, ErrorBoundary, Skeleton } from '@/components';
import CANONICAL_TAG from '@/utils/canonicalTag';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import { paramNames } from '@/constants';
import { serializeInsightsParams } from '@/screens/Dashboard/DashboardPanel/helpers';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import EntitiesList from '@/components/Omnisearch/EntitiesList';
import TagsList from '@/components/TagsList';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import CategoryDataErrorFallback from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/CategoryDataErrorFallback';

const CategoryDataSkeleton = () => (
  <div className={styles.categoryData}>
    <div className={styles.overlay} />
    <div className={styles.categoryHeader}>
      <Skeleton variant="text" width={150} height={30} />
      <Skeleton variant="rectangular" width={30} height={30} style={{ marginLeft: 'auto' }} />
    </div>
    <Skeleton variant="text" width="100%" height={20} />
    <div className={styles.separator} />
    <Skeleton variant="rectangular" width="100%" height={50} />
    <div className={styles.separator} />
    <Skeleton variant="rectangular" width="100%" height={150} />
  </div>
);

const CategoryData = ({ category, onSelect, baseParams }) => {
  const { showBoundary, resetBoundary } = useErrorBoundary();

  const filters = useMemo(() => ({
    parentType: category, take: 5, tags: CANONICAL_TAG, ...baseParams,
  }), [category, baseParams]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetTagsQuery(filters);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: category,
      [paramNames.take]: 3,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
    refetch: insightsRefetch,
  } = useGetInsightQuery(serializedInsightsParams);

  const { result: gptResult, isLoading: isLoadingGPT, error: gptError } = useGPTExplainRecs({ category, baseParams });

  useEffect(() => {
    resetBoundary();
    insightsRefetch();
  }, [baseParams]);

  if (insightsError || tagsError || gptError) {
    const errorPath = insightsError?.data?.errors[0]?.path
      || tagsError?.data?.errors[0]?.path || gptError?.data?.errors[0]?.path;
    if (errorPath && errorPath === 'signal.interests.entities') {
      return null;
    }
    showBoundary(insightsError?.data?.errors[0] || tagsError?.data?.errors[0] || gptError?.data?.errors[0]);
  }

  if (isLoadingEntities || isLoadingGPT || isLoadingTags) {
    return <CategoryDataSkeleton />;
  }

  return (
    <div key={category} className={styles.categoryData} onClick={onSelect(category)}>
      <div className={styles.overlay} />
      <div className={styles.categoryHeader}>
        <span>{urnsToDisplayName(category)}</span>
        <Button color="link"><ArrowForwardIos /></Button>
      </div>
      <span className={styles.categoryDescription}>
        {gptResult}
      </span>
      <div className={styles.separator} />
      {tagsResult?.results?.tags && (
        <TagsList tags={tagsResult?.results?.tags} useCategoryColor category={category} />
      )}
      <div className={styles.separator} />
      {entitiesResult?.results?.entities && (
        <EntitiesList
          resetPadding
          entities={entitiesResult.results.entities}
          entitiesGridClassName={styles.entitiesGridClassName}
        />
      )}
    </div>
  );
};

const CategoriesDataList = ({ onSelect, baseParams }) => {
  const categories = Object.keys(useCategoryPerms());

  return (
    <div className={styles.categoriesDataListGrid}>
      {categories.map((category) => (
        <ErrorBoundary FallbackComponent={CategoryDataErrorFallback} key={category}>
          <CategoryData
            baseParams={baseParams}
            category={category}
            onSelect={onSelect}
          />
        </ErrorBoundary>
      ))}
    </div>
  );
};

export default CategoriesDataList;

import { createTheme } from '@mui/material/styles';
import mainTheme from './mainTheme';

const lightTheme = createTheme(mainTheme, {
  ...mainTheme,
  palette: {
    mode: 'light',
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#020817',
    },
    primary: {
      main: '#2878FF',
      contrastText: '#F8FAFC',
    },
    secondary: {
      main: '#0F172A',
      contrastText: '#F8FAFC',
    },
    accent: {
      main: '#F2F5F8',
      contrastText: '#0F172A',
    },
    muted: {
      main: '#F8FAFC',
      contrastText: '#64748B',
    },
    destructive: {
      main: '#EF4444',
      contrastText: '#F8FAFC',
    },
    success: {
      main: '#00CC33',
      contrastText: '#E6F9EA',
    },
    danger: {
      main: '#FF3300',
      contrastText: '#FFEBE5',
    },
    orange: {
      main: '#FFEEEB',
      light: '#FFF5F3',
      dark: '#E6D6D3',
      contrastText: '#FF5C33',
    },
    purple: {
      main: '#F1EBF5',
      light: '#F6F0FA',
      dark: '#D6C9E1',
      contrastText: '#77329F',
    },
    blue: {
      main: '#E4F3F6',
      light: '#ECF7F9',
      dark: '#C2E0E4',
      contrastText: '#0095A8',
    },
    gray: {
      main: '#808080',
      contrastText: '',
    },
    yellow: {
      main: '#F7EFD4',
      contrastText: '#FF9900',
    },
    lightBlue: {
      main: '#e5f6ff',
      light: '#c1e3f5',
      dark: '#a2ddfc',
      contrastText: '#a0c9ff',
    },
    darkBlue: {
      main: '#1a3b4d',
      light: '#285b76',
      dark: '#126ca1',
      contrastText: '#e0f2ff',
    },
  },
});

export default lightTheme;

import dayjs from 'dayjs';
import { PERMISSION_MAPPING, USER_ROLES } from '../../../constants/roles';

export const formatRangeDate = (historyItem) => {
  if (!historyItem || !historyItem) return '';

  const range = historyItem.split(',');

  const cleanDates = range.map((date) => date.replace(/[()\[\]]/g, '').trim());

  const date = cleanDates[1] === 'infinity' ? cleanDates[0] : cleanDates[cleanDates.length - 1];

  return dayjs(date).isValid() ? dayjs(date).format('MMM D, YYYY') : '';
};

export const getFilteredDashboards = (dashboards, valueFilter) => {
  return Array.isArray(dashboards)
    ? dashboards.filter((dashboard) => dashboard.title?.toLowerCase().includes(valueFilter?.toLowerCase()))
    : [];
};

export const determineRole = (currentPermission) => {
  let role = USER_ROLES.ADMIN;
  Object.entries(PERMISSION_MAPPING).forEach(([key, permissions]) => {
    if (permissions.every((permission) => currentPermission.includes(permission))) {
      role = key;
    }
  });
  return role;
};

export const removeDuplicates = (array, key) => {
  return array.reduce((acc, current) => {
    const x = acc.find((item) => item[key] === current[key]);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);
};

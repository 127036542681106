import { fieldByParamName } from '@/constants';
import { getIsFieldValueEmpty, getParamLabel } from '@/utils';

const getChips = ({
  baseParams, color, onChange, paramNames, params,
}) => {
  const result = [];
  const baseParamsLabels = paramNames
    .filter((paramName) => fieldByParamName[paramName] && !getIsFieldValueEmpty(baseParams[paramName]))
    .map((paramName) => {
      const value = baseParams[paramName];
      return getParamLabel({ paramName, value });
    });

  if (baseParamsLabels.length) {
    result.push({
      label: baseParamsLabels.length === 1 ? baseParamsLabels[0] : baseParamsLabels.join(', '),
      disabled: true,
    });

    paramNames
      .filter((paramName) => fieldByParamName[paramName] && !getIsFieldValueEmpty(params[paramName]))
      .forEach((paramName) => {
        const value = params[paramName];
        if (Array.isArray(value)) {
          value.forEach((valueItem) => {
            result.push({
              color,
              label: getParamLabel({ paramName, value: [valueItem] }),
              onDelete: () => {
                onChange({ key: paramName, value: value.filter((vItem) => vItem !== valueItem) });
              },
            });
          });
        } else {
          result.push({
            color,
            label: getParamLabel({ paramName, value }),
            onDelete: () => {
              onChange({ key: paramName, value: null });
            },
          });
        }
      });
  }

  return result;
};

export default getChips;

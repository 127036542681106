import React, {
  lazy, Suspense,
  useEffect,
} from 'react';
import {
  HashRouter as Router, Route, Routes, Navigate,
} from 'react-router-dom';
import * as ReactRouterDom from 'react-router-dom';
import ModalProvider from 'mui-modal-provider';

import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';

import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { Box } from '@mui/material';
import logout from './lib/logout';
import SupertokensSessionAuthProvider from './SupertokensSessionAuthProvider';
// import LogOut from './pages/LogOut';

// import { createBrowserHistory } from 'history';
import Header from './components/Header/index';
import { usePagePerms } from './lib/usePermissions';
import { UsersnapProvider } from './lib/UsersnapContext';

import ProgressBar from './components/ProgressBar';

import '@qloo/design/src/colors.scss';
import { PreBuiltUIList } from './supertoken-frontend-config';
import V2 from '../v2';
import darkTheme from './themes/darkTheme';
import lightTheme from './themes/lightTheme';
import useTheme from '../v2/src/app/hooks/useTheme';

// const browserHistory = createBrowserHistory();

const ErrorFallback = ({ error }) => {
  const { enqueueSnackbar } = useSnackbar();

  const finalMessage = import.meta.env.MODE === 'development'
    ? error.message : 'An error occurred. Please try refreshing the page and if that does not work, contact support.';
  const stack = import.meta.env.MODE === 'development' ? error.stack : '';

  // Call enqueueSnackbar in a useEffect to ensure it's called only once when the component mounts.
  useEffect(() => {
    enqueueSnackbar(finalMessage, {
      variant: 'error',
      autoHideDuration: 4000,
    });
  }, [error, enqueueSnackbar, finalMessage]);

  return (
    <Box
      sx={{
        marginTop: '20px',
      }}
      role="alert"
    >
      <h1>Insights Issue</h1>
      <p>{finalMessage}</p>
      <p>{stack}</p>
    </Box>
  );
};

const TITLE = import.meta.env.VITE_SITE_TITLE || 'Qloo Insights';
const RouteAndTitle = ({ Component, title }) => (
  <SessionAuth
    onSessionExpired={logout}
    requireAuth
    doRedirection
  >
    <Header />
    <Helmet
      titleTemplate={`%s - ${TITLE}`}
      defaultTitle={TITLE}
    >
      <title>{title}</title>
    </Helmet>
    <Component />
    {/* <LogOut /> */}
  </SessionAuth>
);

// const Home = lazy(() => import('./pages/Search'));
// const TastediveAdmin = lazy(() => import('./TastediveAdmin'));
const Entity = lazy(() => import('./pages/Entity'));
const History = lazy(() => import('./pages/History'));
const RequestGrid = lazy(() => import('./pages/RequestGrid'));
const Logs = lazy(() => import('./pages/Logs'));
const HistoryQuery = lazy(() => import('./pages/HistoryQueryUrl'));
const Config = lazy(() => import('./pages/Config'));
const Clients = lazy(() => import('./pages/Clients'));
const ClientLogs = lazy(() => import('./pages/ClientLogs'));
const ClientLog = lazy(() => import('./pages/ClientLog'));
const AudienceLabel = lazy(() => import('./pages/AudienceLabel'));
const Shareables = lazy(() => import('./pages/Shareables'));
const Admin = lazy(() => import('./pages/Admin'));
const Comparo = lazy(() => import('./pages/Comparo'));
const Insult = lazy(() => import('./pages/Insult'));
const Justify = lazy(() => import('./pages/Justify'));

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: '0px',
    gridTemplateColumns: '120px auto',
    gridTemplateAreas: `"logo header"
                        "content content"`,
    backgroundColor: '#fff',

    [theme.breakpoints.down('m')]: {
      gridTemplateAreas: `"logo header"
                          "content content"`,
    },

    [theme.breakpoints.down('sm')]: {
      gridGap: '10px',
      gridTemplateColumns: 'auto',
      gridTemplateAreas: `
        "logo"
        "header"
        "content"
      `,
    },
  },
  content: {
    gridArea: 'content',
    backgroundColor: '#fff',
    margin: '64px 0 64px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 1%',
    },
    position: 'relative',
    // overflowX: 'scroll',
    // '&::-webkit-scrollbar': {
    //   background: 'transparent',
    // },

    // paddingLeft: '5px',
    // paddingRight: '5px',
    // [theme.breakpoints.up('sm')]: {
    //   paddingLeft: '20px',
    //   paddingRight: '20px',
    // },
  },
  infobox: {
    marginTop: '50px',
  },
}));

const RouterPage = () => {
  const classes = useStyles();
  const { firstUsablePage } = usePagePerms();

  return (
    <ModalProvider>
      <CssBaseline />
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {
            <Suspense fallback={<ProgressBar />}>
              <Routes>
                {/* This shows the login UI on "/auth" route */}
                {getSuperTokensRoutesForReactRouterDom(ReactRouterDom, PreBuiltUIList)}

                <Route
                  path="/"
                  element={
                    firstUsablePage ? <Navigate to={firstUsablePage.to} /> : null
                  }
                />

                <Route path="/entity/:id/:slug" element={<RouteAndTitle Component={Entity} title="Entity" />} />
                <Route path="/entity/:id" element={<RouteAndTitle Component={Entity} title="Entity" />} />
                <Route
                  path="/history/query"
                  element={
                    <RouteAndTitle Component={HistoryQuery} title="HistoryQuery" />
                  }
                />
                <Route path="/history" element={<RouteAndTitle Component={History} title="History" />} />
                <Route path="/requests" element={<RouteAndTitle Component={RequestGrid} title="Requests" />} />
                <Route path="/config" element={<RouteAndTitle Component={Config} title="Config" />} />
                <Route
                  path="/clientLogs/:clientSlug"
                  element={
                    <RouteAndTitle Component={ClientLog} title="Client Logs" />
                  }
                />
                <Route path="/clientLogs" element={<RouteAndTitle Component={ClientLogs} title="Clients" />} />
                <Route path="/clients/*" element={<RouteAndTitle Component={Clients} title="Clients" />} />
                {/* <Route path="/db/admin" element={
                        <RouteAndTitle Component={TastediveAdmin} title="Database Admin" />} */}
                <Route path="/logs" element={<RouteAndTitle Component={Logs} title="Logs" />} />
                <Route
                  path="/audience_label"
                  element={
                    <RouteAndTitle Component={AudienceLabel} title="AudienceLabel" />
                  }
                />
                <Route path="/shareables/*" element={<RouteAndTitle Component={Shareables} title="Shareables" />} />
                {
                  /// ADMIN ROUTE
                  /// CONSIDER TODO: Enable a user configurable default
                  /// feature. For example, some users may want to start at
                  /// the products feature when navigating to `/admin`.
                }
                <Route
                  path="/admin"
                  element={<Navigate to="/admin/clients" replace />}
                />
                <Route
                  path="/admin/:feature"
                  element={<RouteAndTitle Component={Admin} title="Admin" />}
                />
                {
                  /// END ADMIN
                }
                <Route path="/compare" element={<RouteAndTitle Component={Comparo} title="Compare" />} />
                <Route path="/insult" element={<RouteAndTitle Component={Insult} title="insult" />} />
                <Route path="/justify" element={<RouteAndTitle Component={Justify} title="justify" />} />
                <Route path="/*" element={<RouteAndTitle Component={V2} title="V2" />} />
                {/* <Route path="/logout" element={<RouteAndTitle Component={LogOut} title="Logout" />} /> */}
                {/* <Route path="/logout" element={<Logout />} /> */}
              </Routes>
            </Suspense>
            // )
          }
        </div>
      </div>
    </ModalProvider>
  );
};

// const theme = createTheme({
//   palette: {
//     type: 'light',
//     primary: {
//       main: '#929292',
//     },
//   },
// });

const theme = createTheme({
  palette: {
    primary: {
      main: '#0066ff',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#2962ff',
    },
    lightBlue: {
      main: '#e5f6ff',
      light: '#c1e3f5',
      dark: '#a2ddfc',
      contrastText: '#a0c9ff',
    },
    darkBlue: {
      main: '#1a3b4d',
      light: '#285b76',
      dark: '#126ca1',
      contrastText: '#e0f2ff',
    },
    orange: {
      main: '#ffece8',
      light: '#FFDAD2',
      dark: '#FBC5B9',
      contrastText: '#ff5932',
    },
    purple: {
      main: '#f0e9f4',
      light: '#EED7FB',
      dark: '#E3BCF9',
      contrastText: '#735280',
    },
    teal: {
      main: '#e3f4f7',
      light: '#D0F1F7',
      dark: '#ABEAF5',
      contrastText: '#0196aa',
    },
  },
});

// eslint-disable-next-line arrow-body-style
const RouterAuthWrapped = () => {
  const { isDarkMode } = useTheme();

  if (window.location.href.includes('localhost')) {
    return (
      <div>
        <p>To enable sessions across sub-domains, we can no longer support
          localhost as the root domain for local development. Please edit
          your /etc/hosts file (or equivalent in windows) and add `127.0.0.1
          development.insights.qloo.com`.
        </p>
      </div>
    );
  }
  // TODO: dark theme works but all colors need to be replaced with mui variables

  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const theme = React.useMemo(
  //   () => createTheme({
  //     palette: {
  //       mode: prefersDarkMode ? 'dark' : 'light',
  //     },
  //   }),
  //   [prefersDarkMode],
  // );

  // eslint-disable-next-line react/no-unstable-nested-components
  // TODO Fix disabled eslint line
  return (
    <UsersnapProvider>
      <SnackbarProvider maxSnack={3}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <SuperTokensWrapper>
              <SupertokensSessionAuthProvider>
                <div className="App app-container">
                  <Router>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <RouterPage />
                    </QueryParamProvider>
                  </Router>
                </div>
              </SupertokensSessionAuthProvider>
            </SuperTokensWrapper>
          </ThemeProvider>
        </ErrorBoundary>
      </SnackbarProvider>
    </UsersnapProvider>
  );
};

export default RouterAuthWrapped;

import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Slider from '../Slider';

const SliderWithInputs = ({ field, value, onChange }) => {
  const minDefaultValue = field.defaultValue?.[0];
  const maxDefaultValue = field.defaultValue?.[1];
  const minValue = value?.[0];
  const maxValue = value?.[1];

  return (
    <Box gap={4}>
      <Slider
        min={field.min}
        max={field.max}
        onChange={onChange}
        value={value}
        defaultValue={field.defaultValue}
        step={field.step}
      />
      <Box display="flex" gap={2}>
        <Box display="flex" alignItems="center" flex={1} gap={2}>
          <Typography variant="body1">Min</Typography>
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            value={minValue}
            defaultValue={minDefaultValue}
            onChange={(event) => {
              const newMin = Number(event.target.value);
              const max = Number(maxValue || maxDefaultValue);
              if (newMin >= field.min) {
                onChange([newMin, max]);
              } else {
                onChange([(minValue || minDefaultValue), max]);
              }
            }}
            inputProps={{
              step: field.step,
              min: field.min,
              max: field.max,
            }}
          />
        </Box>

        <Box display="flex" alignItems="center" flex={1} gap={2}>
          <Typography variant="body1">Max</Typography>
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            value={maxValue}
            defaultValue={maxDefaultValue}
            onChange={(event) => {
              const newMax = Number(event.target.value);
              const min = Number(minValue || minDefaultValue);
              if (newMax <= field.max) {
                onChange([min, newMax]);
              } else {
                onChange([min, (maxValue || maxDefaultValue)]);
              }
            }}
            inputProps={{
              step: field.step,
              min: field.min,
              max: field.max,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SliderWithInputs;

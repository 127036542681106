import React from 'react';
import { InlineButton } from '@/components';
import { useDescribeLocation } from '@/hooks';

const LocationParam = ({
  location, 
  onClick, 
  placeholder = 'Anywhere', 
  className,
  color,
}) => {
  const { displayLabel } = useDescribeLocation(location);

  return (
    <InlineButton
      color={color}
      className={className}
      onClick={onClick}
    >
      {location?.label ? (
        <span>{displayLabel}</span>
      ) : (
        <span>{placeholder}</span>
      )}
    </InlineButton>
  );
};

export default LocationParam;

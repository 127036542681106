import Opacity from '@mui/icons-material/Opacity';
import Slider from '../Slider';
import Input from '../Input';

import styles from './MapHeatmap.module.scss';

const HeatmapOpacitySlider = ({ value, onChange }) => {
  return (
    <div className={styles.sliderContainer}>
      <Opacity />
      <Slider
        min={0}
        max={1}
        value={value}
        onChange={onChange}
        step={0.01}
        debounceDelay={0}
        noSlots
      />
      <Input
        value={value}
        disabled
        type="number"
        size="small"
        className={styles.heatmapInput}
      />
    </div>
  );
};

export default HeatmapOpacitySlider;

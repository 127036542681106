import clsx from 'clsx';
import { Minus, Plus } from 'react-feather';
import { Box, Typography } from '@mui/material';
import React from 'react';
import styles from './Tag.module.scss';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import { getTagCategories } from '@/utils';

const Tag = ({
  id, name, value, type, color, isSelected, showSelectedIcon, noBorder, noHover, category, useCategoryColor = false, ...rest
}) => {
  const colorStyles = {
    [styles.red]: color === 'red',
    [styles.orange]: color === 'orange',
    [styles.green]: color === 'green',
    [styles.purple]: color === 'purple',
    [styles.blue]: color === 'blue',
    [styles.brown]: color === 'brown',
    [styles.pink]: color === 'pink',
    [styles.yellow]: color === 'yellow',
    [styles.magenta]: color === 'magenta',
  };


  let extractedSubtitle = (type || '').split(':');
  const { broaderTerm, specificTerm } = getTagCategories(type);

  extractedSubtitle = `${broaderTerm} / ${specificTerm}`;

  return (
    <Box
      className={clsx({
        [styles.tagContainer]: true,
        [styles.useCategoryColor]: useCategoryColor,
        [styles.noHover]: noHover,
      }, (!!color && !useCategoryColor && colorStyles))}
      {...(useCategoryColor && {
        style: {
          '--dynamic-category-color': `var(--category-${formatCategoryUrnToCssVar(category)})`,
          '--dynamic-category-secondary-color': `var(--category-${formatCategoryUrnToCssVar(category)}-secondary)`,
        },
      })}
      {...rest}
    >
      <div className={styles.tagBorder} />
      <Box className={clsx({ [styles.tagWrapper]: true, [styles.resetBorder]: noBorder })}>
        <div>
          <Typography className={styles.name} variant="h6">{name}</Typography>
          <Typography className={styles.value} variant="body2">{extractedSubtitle}</Typography>
        </div>
        {showSelectedIcon && (
          <div className={styles.tagSelectedIcon}>
            {!isSelected ? <Plus size={16} />
              : <Minus size={16} />}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Tag;

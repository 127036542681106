import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { OwnershipOverlay } from '@/components';
import useOwnership from '@/screens/Dashboard/hooks/useOwnership';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { useDashboardVeltContext } from '../DashboardVeltProvider';

// Components
import QueryBuilderPopover from '../QueryBuilderPopover';
import MoreOptionsDashboard from '../../HomePage/MoreOptionsDashboard';
import QueryBuilder from '../QueryBuilder/QueryBuilder';

// Hooks

import styles from './DashboardHeader.module.scss';

const DashboardHeader = ({ dashboard }) => {
  const { getQueryBuilderVeltLocation, setVeltLocation } = useDashboardVeltContext();
  const dashboardActions = useDashboardActionsContext();
  const { baseParams, id } = dashboard;

  // Ownership
  const tag = `${id}-header`;
  const headerOwnership = useOwnership(tag);

  const navigate = useNavigate();
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);
  // Clear ownership on unmount
  useEffect(() => () => {
    headerOwnership.clear();
  }, []);

  // Popover
  const [openPopover, setOpenPopover] = useState(null);
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'demoPopper',
  });

  const handleOpen = useCallback((popover) => (e) => {
    setOpenPopover(popover);
    popupState.open(e);
  }, [popupState]);

  const handleClose = () => {
    setOpenPopover(null);
    popupState.close();
  };

  const handleRedirectDashboard = () => {
    const otherDashboard = Object.values(dashboardIds).find((d) => d.id !== id && !d.deletedAt);
    if (otherDashboard && otherDashboard !== id) {
      navigate(`/dashboards/view/${otherDashboard}`);
    } else {
      navigate('/dashboards/view/');
    }
  };

  const handleChange = ({ key, value }) => {
    dashboardActions.changeBaseParam({ key, value });
  };

  const handleReset = (paramNames) => {
    const nextBaseParams = { ...baseParams };
    paramNames.forEach((paramName) => {
      nextBaseParams[paramName] = null;
    });

    dashboardActions.updateBaseParams(nextBaseParams);
  };

  const veltLocation = useMemo(
    () => getQueryBuilderVeltLocation({ dashboardId: dashboard.id }),
    [dashboard.id],
  );

  useEffect(
    () => {
      setVeltLocation(veltLocation);
    },
    [veltLocation],
  );

  return (
    <OwnershipOverlay tag={tag}>
      <div className={styles.container}>
        <div className={styles.queryBuilder}>
          <QueryBuilder params={baseParams} onOpen={handleOpen} veltLocation={veltLocation}>
            {({ DemographicsComponent, InterestsComponent, LocationComponent }) => (
              <div className={styles.baseQuery}>
                <DemographicsComponent color='orange' className={styles.picker} />
                &nbsp;
                <div>from</div>
                &nbsp;
                <LocationComponent color='purple' className={styles.picker} />
                &nbsp;
                <div>that like{baseParams?.gender ? '' : 's'}</div>
                &nbsp;
                <InterestsComponent color='blue' className={styles.picker} />
              </div>
            )}
          </QueryBuilder>
          <QueryBuilderPopover
            lock={headerOwnership.set}
            popupState={popupState}
            onChange={handleChange}
            onClose={handleClose}
            onReset={handleReset}
            openPopover={openPopover}
            params={baseParams}
            releaseLock={headerOwnership.clear}
          />
        </div>
        <MoreOptionsDashboard dashboard={dashboard} showFavorite handleConfirmDeleteAdditional={handleRedirectDashboard} />
      </div>
    </OwnershipOverlay>
  );
};

export default DashboardHeader;

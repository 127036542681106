import geoJsonAreaSizeMeters from '@turf/area';
import { geojsonToWKT, wktToGeoJSON } from '@terraformer/wkt';
import { toHumanString } from 'human-readable-numbers';
import centroid from '@turf/centroid';

export const featureToCollection = (geometry) => (geometry?.type === 'FeatureCollection' ? geometry : ({
  type: 'FeatureCollection',
  features: geometry ? [{
    type: 'Feature',
    properties: {},
    geometry,
  }] : [],
}));

export const featuresToCollection = (features) => (features?.type === 'FeatureCollection' ? features : ({
  type: 'FeatureCollection',
  features: features || [],
}));

// export const lonLatRe = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?), \s*[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
// // latLonRe
// export const llre = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

// Simpler, because we're using lon,lat (mapbox) and lat,lon (us)
export const llre = /^[-+]?\d+\.\d+,\s*[-+]?\d+\.\d+$/;

// latLon to lonLat
export const reverseLL = (str) => str.split(',').reverse().join(',');

export const getLLCenter = (geoJson) => centroid(geoJson)?.geometry?.coordinates?.join(',');

export const geoJsonAreaSizeMiles = (geoJson) => {
  try {
    const meters = geoJsonAreaSizeMeters(geoJson);
    if (!meters) return null;
    const miles = meters * 0.00000038610215855;
    return miles;
  } catch (e) {
    return null;
  }
};

const UNITS = {
  miles: 'miles',
  meters: 'meters',
};

export const geoJsonAreaSize = (geoJson, { unit = UNITS.miles } = {}) => {
  try {
    let size = unit === UNITS.miles ? geoJsonAreaSizeMiles(geoJson) : geoJsonAreaSizeMeters(geoJson);

    let unitName;
    if (unit === UNITS.miles) {
      unitName = 'mi²';
    } else {
      // eslint-disable-next-line no-lonely-if
      if (size >= 1000) {
        size /= 1000;
        unitName = 'km²';
      } else {
        unitName = 'm²';
      }
    }

    return `${size < 1 ? 'less than 1' : toHumanString(size)} ${unitName} region`;
  } catch (e) {
    return null;
  }
};

export const geoJsonToWkt = (geojson) => {
  try {
    // console.log('parsing geoJson', geojson);
    return geojsonToWKT(geojson);
  } catch (e) {
    console.warn('geoJson parsing warning', geojson, `${e.toString() }`);
    return null;
  }
};

export const WktToGeoJson = (wktString) => {
  // TODO: kludge, not sure where wktString is being array'd as of yet, maybe in QlooURL parser?
  /* eslint-disable react/destructuring-assignment */
  const wkta = Array.isArray(wktString) ? wktString.join(',') : wktString;
  try {
    // console.log('parsing wkt', wktString);
    return wktToGeoJSON(wkta);
  } catch (e) {
    console.warn('wkt parsing warning', wkta, `${e.toString() }`);
    return null;
  }
};

export const LLToWktPoint = ({ lon, lat }) => `POINT(${lon} ${lat})`;

export const radiusHumanizer = (meters) => {
  if (meters < 1000) {
    return `${meters} m radius around`;
  }

  return `${toHumanString(meters / 1000)} km radius around`;
};

export const locationHumanizer = (location) => {
  if (!location) return null;

  if (!location.label) return null;

  // Only show radius for POINT locations that are not stringified
  if (!location.label.startsWith('"') && location.value.startsWith('POINT') && location.radius) {
    return `${radiusHumanizer(location.radius)} ${location.label}`;
  }

  return location.label;
};

import React from 'react';
import { getIsTagId } from '@/utils';
import OmnisearchResultsTopTag from './OmnisearchResultsTopTag';
import OmnisearchResultsTopEntity from './OmnisearchResultsTopEntity';
import styles from './Omnisearch.module.scss';

const OmnisearchResultsTop = ({ getItemProps, isSelected, topResult }) => {
  const isTag = getIsTagId(topResult.id || '');
  return (
    <div className={styles.topResult}>
      <div className={styles.resultsTitle}>Top Result</div>
      {isTag ? (
        <OmnisearchResultsTopTag
          isSelected={isSelected}
          itemProps={getItemProps({
            index: 0,
            item: topResult.entity_id || topResult.id,
          })}
          tag={topResult}
        />
      ) : (
        <OmnisearchResultsTopEntity
          entity={topResult}
          itemProps={getItemProps({
            index: 0,
            item: topResult.entity_id || topResult.id,
          })}
          isSelected={isSelected}
        />
      )}
    </div>
  );
};

export default OmnisearchResultsTop;

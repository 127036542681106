import React from 'react';
import clsx from 'clsx';
import { Trash, Copy, Tool } from 'react-feather';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { api, paramNames } from '@/constants';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import getPanelHeaderTitle from '@/utils/getPanelHeaderTitle';
import useTheme from '@/hooks/useTheme';
import PanelParams from '@/screens/Dashboard/DashboardPanel/PanelParams';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { useDashboardVeltContext } from '../DashboardVeltProvider';

import styles from './DashboardPanel.module.scss';

const PanelHeader = ({
  panelId, baseParams, panelParams, serializedInsightsParams,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const dashboardVelt = useDashboardVeltContext();
  const [anchorEl, setContextMenuanchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { isDarkMode } = useTheme();
  const { isCommentingToggle } = dashboardVelt;
  const filterType = panelParams?.[paramNames.filterType];

  const handlePanelDelete = () => {
    dashboardActions.deletePanel(panelId);
  };

  const handlePanelClone = () => {
    dashboardActions.clonePanel(panelId);
  };

  const handleOpenInRequestBrowser = () => {
    const requestUrl = `${api.server}/v2/insights?${serializedInsightsParams}`;
    window.open(
      `/#/requests?requests=${encodeURIComponent(requestUrl)}`,
      '_blank',
    );
  };

  const handlePanelContextMenuClick = (event) => {
    setContextMenuanchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setContextMenuanchorEl(null);
  };

  return (
    <div className={styles.panelHeaderWrapper}>
      <div className={styles.panelHeader}>
        <div
          className={clsx({
            'panel-drag-handle': !isCommentingToggle.value,
            [styles.panelHeaderLeft]: true,
          })}
          data-velt-sync-access="true"
        >
          <span>{getPanelHeaderTitle(filterType)}</span>
        </div>

        <div className={styles.panelHeaderRight}>
          {/* <MakeCrashButton message="🐞 Panel Error: Make Crash executed" title="Make Crash" /> */}

          <IconButton className={clsx(styles.contextMenu, { [styles.dark]: isDarkMode })}>
            <MoreHorizIcon
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handlePanelContextMenuClick}
              size={20}
            />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleContextMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem className={styles.contextMenuItemWrapper} onClick={handlePanelClone}>
              <Copy size={16} />  <span className={styles.contextMenuItemText}>Clone Panel</span>
            </MenuItem>
            <MenuItem className={styles.contextMenuItemWrapper} onClick={handleOpenInRequestBrowser}>
              <Tool size={16} />  <span className={styles.contextMenuItemText}>Open in request browser</span>
            </MenuItem>
            <MenuItem className={styles.contextMenuItemWrapper} onClick={handlePanelDelete}>
              <Trash size={16} />  <span className={styles.contextMenuItemText}>Delete Panel</span>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <PanelParams
        baseParams={baseParams}
        panelId={panelId}
        panelParams={panelParams}
      />
    </div>
  );
};

export default PanelHeader;

import React, { useEffect, useState } from 'react';
import { Slider as MuiSlider } from '@mui/base/Slider';
import './Slider.scss';

const SliderValueLabel = ({ children }) => (
  <span className="label">
    <div className="value">{children}</div>
  </span>
);

const Slider = ({
  min, max, onChange, step, value, defaultValue, debounceDelay = 500, noSlots,
}) => {
  const [sliderValue, setSliderValue] = useState(value);

  const handleChange = (e, nextValue) => {
    setSliderValue(nextValue);
  };

  useEffect(() => {
    if (sliderValue !== value) {
      setSliderValue(value);
    }
  }, [value]);

  // Debounce
  useEffect(
    () => {
      if (sliderValue) {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          onChange(sliderValue);
        }, debounceDelay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      }

      return undefined;
    },
    [sliderValue], // Only re-call effect if value or delay changes
  );

  return (
    <MuiSlider
      min={min}
      max={max}
      onChange={handleChange}
      slots={!noSlots && { valueLabel: SliderValueLabel }}
      step={step || 1}
      value={sliderValue}
      defaultValue={defaultValue}
    />
  );
};

export default Slider;

import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MapHeatmap } from '@/components';
import { useDashboardActionsContext } from '../DashboardActionsProvider';

import styles from './DashboardPanel.module.scss';

const PanelBodyHeatmap = ({ data, panelId, heatmapControls }) => {
  const elementRef = useRef(null);
  const [mapKey, setMapKey] = useState(uuidv4());
  const dashboardActions = useDashboardActionsContext();

  const handleControlsChange = (nextHeatmapControls) => {
    const nextSettings = { heatmapControls: nextHeatmapControls };
    dashboardActions.changePanelSettings({ panelId, nextSettings });
  };

  useEffect(() => {
    if (elementRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setMapKey(uuidv4());
      });
      resizeObserver.observe(elementRef.current);
      return () => resizeObserver.disconnect();
    }
    return null;
  }, [elementRef]);

  return (
    <div ref={elementRef} className={styles.heatmapContainer}>
      <MapHeatmap
        data={data}
        controls={heatmapControls}
        onControlsChange={handleControlsChange}
        key={mapKey}
      />
    </div>
  );
};

export default PanelBodyHeatmap;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';

import { ErrorBoundary } from '@/components';
import { useGetDashboardQuery } from '@/store/apis/dashboardsApi';
import { addAndPersistDashboardTabId } from '@/store/slices/dashboardTabsSlice';

import DashboardHeader from '../DashboardHeader/DashboardHeader';
import DashboardBody from '../DashboardBody/DashboardBody';
import DashboardViewSkeleton from './DashboardViewSkeleton';
import DashboardErrorFallback from '../DashboardErrorFallback/DashboardErrorFallback';

import DashboardActionsProvider from '../DashboardActionsProvider';
import DashboardVeltProvider from '../DashboardVeltProvider';

const DashboardContainerWithBoundary = (props) => (
  <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
    <DashboardContainer {...props} />
  </ErrorBoundary>
);

const DashboardContainer = ({ isLoading }) => {
  const { id } = useParams();
  const { showBoundary } = useErrorBoundary();
  const dashboard = useSelector((state) => state.dashboards.items[id]);

  // Fetch dashboard if not in store
  const {
    isLoading: isDashboardLoading,
    error: dashboardError,
  } = useGetDashboardQuery(
    { id },
    { skip: isLoading || !id || (dashboard && dashboard.baseParams) },
  );

  if (isDashboardLoading || isLoading) {
    return (
      <DashboardViewSkeleton />
    );
  }

  if (dashboardError || !dashboard || !dashboard.baseParams) {
    showBoundary({
      dashboardId: id,
      data: dashboardError?.data,
      status: dashboardError?.status,
    });
    return null;
  }

  return (
    <DashboardVeltProvider dashboard={dashboard}>
      <DashboardActionsProvider dashboard={dashboard}>
        <Dashboard dashboard={dashboard} key={id} />
      </DashboardActionsProvider>
    </DashboardVeltProvider>
  );
};

const Dashboard = ({ dashboard }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addAndPersistDashboardTabId(dashboard.id));
  }, [dashboard.id]);

  return (
    <div>
      <DashboardHeader dashboard={dashboard} />
      <DashboardBody dashboard={dashboard} />
    </div>
  );
};

export default DashboardContainerWithBoundary;

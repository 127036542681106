import { DEFAULT_RADIUS, filterTypes, paramNames } from '@/constants';
import { isObject } from '@/utils';

const getLocation = (location) => {
  if (!isObject(location)) {
    return {
      value: location,
    };
  }

  if (!location.value || typeof location.value !== 'string') {
    return null;
  }

  if (location.value.startsWith('POINT') && !location.radius) {
    return {
      ...location,
      radius: DEFAULT_RADIUS,
    };
  }

  return location;
};

const legacyDashboardMapper = (d) => {
  try {
    const {
      panels, baseParams, layout, ...rest
    } = d;

    const nextBaseParams = Object.entries(baseParams).reduce((acc, [key, value]) => {
      let nextValue = structuredClone(value);

      if (key === paramNames.signalLocation) {
        nextValue = getLocation(value);
      }

      return {
        ...acc,
        [key]: nextValue,
      };
    }, {});

    // Remove panels without layout
    const validPanelIds = layout.map((item) => item.i);
    const validPanelIdsBitmap = validPanelIds.reduce((acc, id) => ({ ...acc, [id]: 1 }), {});

    const nextPanels = Object.entries(panels).reduce((acc, [panelId, panel]) => {
      const hasLayout = !!validPanelIdsBitmap[panelId];

      if (!hasLayout) {
        return acc;
      }

      const nextPanel = structuredClone(panel);

      if (panel.params) {
        if (panel.params[paramNames.filterType] === filterTypes.heatmap) {
          const filterLocation = panel.params[paramNames.filterLocation];

          if (filterLocation) {
            nextPanel.params[paramNames.filterLocation] = getLocation(filterLocation);
          }
        }
      }

      return {
        ...acc,
        [panelId]: nextPanel,
      };
    }, {});

    return {
      ...rest,
      baseParams: nextBaseParams,
      panels: nextPanels,
      layout,
    };
  } catch (error) {
    // TODO: add Sentry
    console.error('legacyDashboardMapper error', error);
    return d;
  }
};

export default legacyDashboardMapper;

import React from 'react';
import clsx from 'clsx';
import { Skeleton } from '@/components';

import styles from './DashboardToolbar.module.scss';

const DashboardToolbarSkeleton = ({ disabled }) => (
  <div>
    <div className={styles.toolbar}>
      <div className={styles.toolbarLeft}>
        <Skeleton circle disabled={disabled} baseColor="hsl(var(--yellow-foreground))" width={40} height={42} />
      </div>
      <div className={styles.toolbarRight}>
        <div className={styles.toolbarItem}>
          <Skeleton disabled={disabled} circle width={18} height={18} />
        </div>
        <div className={styles.toolbarItem}>
          <Skeleton disabled={disabled} circle width={18} height={18} />
        </div>
        <div className={clsx(styles.toolbarSkeletonPadding, styles.toolbarItem)}>
          <Skeleton disabled={disabled} width={48} height={18} />
        </div>
      </div>
    </div>
  </div>
);

export default DashboardToolbarSkeleton;

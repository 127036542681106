import Edit from '@mui/icons-material/Edit';
import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { Button, Modal } from '@/components';
import { useToggle } from '@/hooks';
import { useUpdateDashboardNameMutation } from '@/store/apis/dashboardsApi';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';
import style from './DashboardRename.module.scss';

const DashboardRename = ({
  dashboard, disabled, title, onClose,
}) => {
  const isOpenToggle = useToggle();
  const [dashboardTitle, setDashboardTitle] = useState(dashboard.title);
  const [updateDashboardName, { isLoading }] = useUpdateDashboardNameMutation();

  const handleChange = (e) => {
    setDashboardTitle(e.currentTarget.value);
  };

  const handleSubmit = async () => {
    try {
      await updateDashboardName({ dashboardId: dashboard.dashboardId, title: dashboardTitle }).unwrap();
      isOpenToggle.off();
      onClose();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <Box
        className={clsx({ [style.container]: true, [style.containerDisabled]: disabled })}
        onClick={isOpenToggle.on}
      >
        <Edit color="disabled" />
        {title && (
          <Typography variant="subtitle2">{title}</Typography>
        )}
      </Box>
      <Modal
        isOpen={isOpenToggle.value}
        close={isOpenToggle.off}
        title="Rename Dashboard"
        renderContent={() => (
          <Box minWidth={400} display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column" gap={2} mt={2} pl={2} pr={2}>
              <TextField
                required
                error={!dashboardTitle}
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                value={dashboardTitle}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </Box>
            <div className={style.containerFooterButton}>
              <Button onClick={isOpenToggle.off} variant="secondary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} isLoading={isLoading} disabled={!dashboardTitle}>
                Rename Dashboard
              </Button>
            </div>
          </Box>
        )}
      />
    </FeatureNoAccessTooltip>
  );
};

export default DashboardRename;

import React from 'react';
import { Box, Chip } from '@mui/material';
import { USER_ROLES, PERMISSION_LABELS } from '../../constants/roles';

const PermissionChips = ({ permissions }) => {
    const formatPermissions = (permissions) => {
        if (!Array.isArray(permissions)) {
            permissions = [permissions];
        }
        return permissions.map((permission) => PERMISSION_LABELS[permission] || permission);
    };

    return (
        <Box display="flex" gap={1} flexWrap="wrap" alignItems="center">
            {formatPermissions(permissions).map((permission, index) => (
                <Chip key={index} label={permission} variant="outlined" size="small" sx={{ color: 'gray' }} />
            ))}
        </Box>
    );
};

export default PermissionChips;

import React from 'react';
import clsx from 'clsx';
import { Skeleton } from '@/components';
import styles from './DashboardTabs.module.scss';

const DashboardTabsAddButtonSkeleton = () => (
  <div className={clsx(styles.button, styles.pastelBackground)}>
    <Skeleton width="100%" height="100%" />
  </div>
);

export default DashboardTabsAddButtonSkeleton;

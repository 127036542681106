import { CgMagnet } from "react-icons/cg";
import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";
import { List, TrendingUp } from "react-feather";

const HeatmapToggle = ({ value, onChange }) => {
  return (
    <CheckboxGroup
      value={value}
      onChange={onChange}
      options={[
        { id: 'affinity', label: <CgMagnet size={24} />, color: 'accent' },
        { id: 'popularity', label: <TrendingUp size={24} />, color: 'accent' },
        { id: 'affinity_rank', label: <List size={24} />, color: 'accent' },
      ]}
    />
  );
};

export default HeatmapToggle;
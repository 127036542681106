import { urnsToDisplayName } from '@qloo/categories';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, LogoLoading, Table, Tag,
} from '@/components';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import { paramNames } from '@/constants';
import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';
import { serializeInsightsParams } from '@/screens/Dashboard/DashboardPanel/helpers';
import { affinityColumn, nameColumn, rankColumn } from '@/screens/Dashboard/DashboardPanel/helpers/getColumns';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import CANONICAL_TAG from '@/utils/canonicalTag';
import styles from './PanelBodyExplore.module.scss';

const TagCell = ({ row, selectedCategory }) => {
  return (
    <Tag
      noBorder
      noHover
      key={row.tagId}
      useCategoryColor
      {...row.original}
      isSelected={false}
      showSelectedIcon={false}
      type={row?.original?.subtype}
      category={selectedCategory}
    />
  );
};

const TableWithTitle = ({
  title, data, columns, isLoading, onSelect,
}) => {
  const tableData = useMemo(() => {
    return data;
  }, [data]);
  return (
    <div className={styles.tableWithTitle}>
      <Button variant="text" className={styles.tableTitle} onClick={onSelect}>{title}</Button>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={tableData}
        getRowId={({ row }) => row.entity_id}
        renderEmptyRowsFallback={() => (
          <div className={styles.tableEmptyFallback}>
            {isLoading
              ? <LogoLoading shouldPulse size="large" />
              : <span>No Results Found</span>}
          </div>
        )}
      />
    </div>
  );
};

const TableTitles = {
  ENTITIES: 'Entities',
  TAGS: 'Tags',
};

const SelectedCategoryContent = ({
  selectedCategory, handleSelectedCategory, baseParams, updateHasMoreExplorePages, pagination, panelId, exploreControls,
}) => {
  const [selectedData, setSelectedData] = useState();
  const dashboardActions = useDashboardActionsContext();

  useEffect(() => {
    setSelectedData(exploreControls?.specificData || null);
  }, [exploreControls?.specificData]);

  const serializedTagsInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: 'urn:tag',
      tags: CANONICAL_TAG,
      offset: pagination[paramNames.offset],
      take: pagination[paramNames.take] + 1,
    };
    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, pagination]);

  const {
    data: tagsResult,
    isLoading: isTagsLoading,
    error: insightsTagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams, {
    skip: selectedData === 'ENTITIES',
  });

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: selectedCategory,
      offset: pagination[paramNames.offset],
      take: pagination[paramNames.take] + 1,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, selectedCategory, pagination]);

  const {
    data: entitiesResult,
    isLoading: isEntitiesLoading,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams, {
    skip: selectedData === 'TAGS',
  });

  const entities = useMemo(
    () => (insightsError ? [] : entitiesResult?.results?.entities.slice(0, pagination[paramNames.take])),
    [entitiesResult?.results?.entities, insightsError, pagination],
  );

  const tags = useMemo(
    () => (insightsTagsError ? [] : tagsResult?.results?.tags.slice(0, pagination[paramNames.take])),
    [tagsResult?.results?.tags, insightsTagsError, pagination],
  );

  const entitiesCount = entitiesResult?.results?.entities?.length || 0;
  const tagsCount = tagsResult?.results?.tags?.length || 0;

  useMemo(
    () => {
      updateHasMoreExplorePages(entitiesCount > pagination[paramNames.take]
        || tagsCount > pagination[paramNames.take]);
    },
    [entitiesCount, tagsCount, pagination],
  );

  const handleChangePanelSettings = (nextExploreControls) => {
    dashboardActions.changePanelSettings({
      panelId,
      nextSettings: {
        exploreControls: nextExploreControls?.selectedCategory === null
          ? nextExploreControls : { ...exploreControls, ...nextExploreControls },
      },
    });
  };

  const handleSelectSpecificData = (type) => () => {
    setSelectedData(type);
    handleChangePanelSettings({ specificData: type });
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <span className={styles.name}>{urnsToDisplayName(selectedCategory)}</span>
        <Breadcrumb items={[
          {
            label: 'Categories',
            onClick: () => {
              handleSelectedCategory(null)();
              updateHasMoreExplorePages(false);
              handleChangePanelSettings({ selectedCategory: null });
            },
          },
          {
            label: urnsToDisplayName(selectedCategory),
            ...(!!selectedData && {
              onClick: () => {
                handleChangePanelSettings({ specificData: null });
              },
            }),
          },
          (!!selectedData && { label: TableTitles[selectedData] }),
        ]}
        />
      </div>
      <div className={clsx({ [styles.data]: true, [styles.singleData]: !!selectedData })}>
        {(!selectedData || selectedData === 'ENTITIES') && (
          <TableWithTitle
            isLoading={isEntitiesLoading}
            title="Entities"
            columns={[
              rankColumn,
              nameColumn,
              affinityColumn,
            ]}
            data={entities || []}
            onSelect={handleSelectSpecificData(selectedData === 'ENTITIES' ? null : 'ENTITIES')}
          />
        )}
        {(!selectedData || selectedData === 'TAGS') && (
          <TableWithTitle
            isLoading={isTagsLoading}
            title="Tags"
            columns={[
              {
                ...nameColumn,
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: (props) => <TagCell {...props} selectedCategory={selectedCategory} />,
              },
              affinityColumn,
            ]}
            data={tags || []}
            onSelect={handleSelectSpecificData(selectedData === 'TAGS' ? null : 'TAGS')}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedCategoryContent;

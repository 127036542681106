import React, { useState } from 'react';
import {
  Box, Typography, Checkbox, TextField,
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Modal, Button } from '@/components';

import { useCloneDashboardMutation } from '@/store/apis/dashboardsApi';
import useScrollBlock from '@/hooks/useScrollBlock';
import style from './DashboardClone.module.scss';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';

const DashboardClone = ({
  dashboard, label, onConfirmAdditional, disabled,
}) => {
  const { dashboardId, title } = dashboard;
  const navigate = useNavigate();
  const [cloneDashboard, { isLoading }] = useCloneDashboardMutation();

  const [cloneOpen, setCloneOpen] = useState(false);
  const [valueName, setValueName] = useState(`${title} (Copy)`);
  const [isShareWithSamePeople, setIsShareWithSamePeople] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  const handleDeleteClose = () => {
    allowScroll();
    setCloneOpen(false);
  };

  const handleConfirm = async () => {
    const newDashboard = {
      dashboardId,
      shareWithSamePeople: isShareWithSamePeople,
      newTitle: valueName,
    };
    const { data } = await cloneDashboard(newDashboard);
    setCloneOpen(false);
    allowScroll();
    onConfirmAdditional();
    navigate(`/dashboards/view/${data.id}`);
  };

  const handleOpenDelete = () => {
    setCloneOpen(true);
    blockScroll();
  };

  const handleFilterChange = (e) => {
    setValueName(e.target.value);
  };

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <Box
        className={clsx({ [style.container]: true, [style.containerDisabled]: disabled })}
        onClick={handleOpenDelete}
      >
        <FileCopyOutlinedIcon color="disabled" />
        {label && <Typography variant="subtitle2">{label}</Typography>}
      </Box>
      <Modal
        isOpen={cloneOpen}
        close={handleDeleteClose}
        title="Clone Dashboard"
        renderContent={() => (
          <Box minWidth={400} display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column" gap={2} mt={2} pl={2} pr={2}>
              <TextField
                required
                error={!valueName}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                fullWidth
                value={valueName}
                onChange={handleFilterChange}
              />
              <Box className={style.containerCheckbox} onClick={() => setIsShareWithSamePeople(!isShareWithSamePeople)}>
                <Checkbox checked={isShareWithSamePeople} />
                <Typography variant="subtitle2">Share it with the same people</Typography>
              </Box>
            </Box>
            <Box className={style.containerFooterButton}>
              <Button onClick={handleDeleteClose} variant="secondary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} isLoading={isLoading} disabled={!valueName}>
                Make a clone
              </Button>
            </Box>
          </Box>
        )}
      />
    </FeatureNoAccessTooltip>
  );
};

export default DashboardClone;

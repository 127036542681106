import React, { useState } from 'react';
import {
  Card, IconButton, Typography, Box, CardActions, Collapse, CardContent, CardHeader, styled, Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { formatRangeDate } from '../helpers/dashboardHelpers';
import { ReactComponent as Logo } from '../../../../../../src/components/LogoSm.svg';
import PermissionChips from '../PermissionChips';
import DashboardUserChipAvatarGroup from '../DashboardChipAvatar/DashboardUserChipAvatarGroup';
import styles from './DashboardCard.module.scss';
import FavoriteDashboard from '../FavoriteDashboard';
import useIsOwner from '../hooks/useIsOwner';
import getTitle from '../../Dashboard/DashboardTabs/getTitle';
import MoreOptionsDashboard from '../MoreOptionsDashboard';
import useUserDashboardIdentity from '@/screens/HomePage/hooks/useUserDashboardIdentity';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DashboardCard = ({
  data,
  onClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  const userIdentity = useUserDashboardIdentity(data.id);
  const isUserOwner = useIsOwner(data);
  const isDashboardShared = isUserOwner && data.identities?.length > 1;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onClickRedirect = () => {
    onClick(data.id);
  };

  return (
    <Card className={styles.card}>
      <CardHeader
        action={(
          <MoreOptionsDashboard dashboard={data} />
        )}
        title={(
          <Box display="flex" alignItems="center" gap={1} onClick={onClickRedirect} sx={{ cursor: 'pointer' }}>
            <FavoriteDashboard
              dashboardId={data.dashboardId}
              identityId={userIdentity?.identityId}
              isFavorite={userIdentity?.isFavorite}
            />
            <Tooltip title={getTitle(data)} placement="top">
              <Typography
                noWrap
                maxWidth={180}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {getTitle(data)}
              </Typography>
            </Tooltip>
          </Box>
        )}
        subheader={(
          <Box paddingLeft={4} onClick={onClickRedirect} sx={{ cursor: 'pointer' }}>
            <Typography variant="subtitle2" color="gray">{formatRangeDate(data.events[0].operationTimeRange)}</Typography>
          </Box>
        )}
        titleTypographyProps={{ variant: 'h6' }}
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <Box
        onClick={onClickRedirect}
        className={styles.thumbnailBox}
        sx={{ backgroundImage: `url(${data.thumbnail})` }}
      >
        {!data.thumbnail
          && <Logo className="logoSvg" />}
      </Box>
      <CardActions disableSpacing className={styles.cardActions}>
        <Box display="flex" alignItems="center" gap={1} ml={1}>
          {isDashboardShared ? <GroupsIcon color="disabled" sx={{ fontSize: 28 }} /> : isUserOwner
            ? <PersonIcon color="disabled" /> : <BusinessIcon color="disabled" sx={{ fontSize: 28 }} />}
          <Typography
            variant="subtitle2"
            fontWeight={500}
            color="gray"
            sx={{ fontStyle: 'italic' }}
          >Updated at {formatRangeDate(data.events?.[data.events?.length - 1].operationTimeRange)}
          </Typography>
        </Box>
        {(isDashboardShared || !isUserOwner) && (
          <ExpandMore
            sx={{ margin: 0 }}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={styles.cardContent}>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={1}
          >
            {!isDashboardShared && (
              <Box display="flex" gap={1}>
                <Box display="flex" alignItems="center" gap={1.5}>
                  <LockPersonIcon color="disabled" />
                  <Typography variant="subtitle2" color="gray">Permissions</Typography>
                </Box>
                <PermissionChips permissions={data.permissionTagIds} />
              </Box>
            )}

            {data.identities?.length > 1 && (
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1.5} mt={1}>
                <Box display="flex" alignItems="center" gap={1.5}>
                  <PeopleAltIcon color="disabled" />
                  <Typography variant="subtitle2" width="100%" color="gray">Shared With</Typography>
                </Box>
                <DashboardUserChipAvatarGroup
                  data={data.identities.filter((el) => el.identityId !== userIdentity?.identityId)}
                  fontSizeLabel="1.5em"
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default DashboardCard;

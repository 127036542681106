// hooks/useIsOwner.js
import { useSelector } from 'react-redux';

function useIsOwner(dashboard) {
    const { user } = useSelector((state) => state.app);

    const isOwner = dashboard.identities?.some(identity =>
        identity.supertokensIdentityExternalId === user.userId &&
        identity.permissionTagIds.includes('role.owner')
    );

    return isOwner;
}

export default useIsOwner;

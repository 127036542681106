import {
  createContext, React, useContext, useEffect, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useVeltClient } from '@veltdev/react';
import { useToggle } from '@/hooks';
import { getHash } from '@/utils';

const useDashboardVelt = ({ veltClient }) => {
  const isCommentingToggle = useToggle();
  const isVeltInitializedToggle = useToggle(false);

  const init = ({ dashboardId, user }) => {
    try {
      veltClient.identify(user);
      veltClient.setDocumentId(dashboardId);
      const liveStateSyncElement = veltClient.getLiveStateSyncElement();
      liveStateSyncElement.enableSingleEditorMode({ customMode: true });
      liveStateSyncElement.enableDefaultSingleEditorUI();
      liveStateSyncElement.singleEditorModeContainerIds(['dashboard']);

      const commentElement = veltClient.getCommentElement();
      commentElement.disableCommentPinHighlighter();
      commentElement.disableTextComments();

      isVeltInitializedToggle.on();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const getQueryBuilderVeltLocation = ({ dashboardId }) => {
    const additional = {
      area: 'query-builder',
      dashboardId,
    };

    const locationString = JSON.stringify(additional);
    const veltLocationId = getHash(locationString);

    const location = {
      id: veltLocationId,
      locationName: 'Query Builder',
    };

    return location;
  };

  const getPanelVeltLocation = ({ panelId }) => {
    const additional = {
      panelId,
    };

    const locationString = JSON.stringify(additional);
    const veltLocationId = getHash(locationString);
    const locationName = panelId;

    const location = {
      id: veltLocationId,
      locationName,
      ...additional,
    };

    return location;
  };

  const onLayoutChangeStart = () => {
    if (veltClient) {
      const liveStateSyncElement = veltClient.getLiveStateSyncElement();
      liveStateSyncElement.setUserAsEditor();
    }
  };

  const onLayoutChangeEnd = () => {
    if (veltClient) {
      const liveStateSyncElement = veltClient.getLiveStateSyncElement();
      liveStateSyncElement.resetUserAccess();
    }
  };

  const setVeltLocation = (location) => {
    if (veltClient) {
      veltClient.setLocation(location, true);
    }
  };

  return useMemo(() => ({
    init,
    isCommentingToggle,
    isVeltInitialized: isVeltInitializedToggle.value,
    getQueryBuilderVeltLocation,
    getPanelVeltLocation,
    onLayoutChangeEnd,
    onLayoutChangeStart,
    setVeltLocation,
    veltClient,
  }), [isCommentingToggle.value, isVeltInitializedToggle.value, veltClient]);
};

const DashboardVeltContext = createContext(null);

export const useDashboardVeltContext = () => useContext(DashboardVeltContext);

const DashboardVeltProvider = ({ children, dashboard }) => {
  const { client: veltClient } = useVeltClient();
  const { dashboardId } = dashboard;
  const dashboardVelt = useDashboardVelt({ veltClient });

  const { init, onLayoutChangeEnd } = dashboardVelt;

  const { user } = useSelector((state) => state.app);

  useEffect(() => {
    if (veltClient) {
      init({ dashboardId, user });
    }
  }, [veltClient]);

  useEffect(() => () => {
    onLayoutChangeEnd();
  }, []);

  return (
    <DashboardVeltContext.Provider value={dashboardVelt}>
      {children}
    </DashboardVeltContext.Provider>
  );
};

export default DashboardVeltProvider;

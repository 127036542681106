import React from 'react';
import styles from './RatingRange.module.scss';
import Rating from '../Rating';

const RatingRange = ({ defaultValue, onChange, value }) => {
  const [valueMin, valueMax] =
    value && Array.isArray(value)
      ? value
      : [
          defaultValue ? defaultValue[0] : null,
          defaultValue ? defaultValue[1] : null,
        ];

  const onChangeMin = (nextMin) => {
    onChange([nextMin, valueMax]);
  };

  const onChangeMax = (nextMax) => {
    onChange([valueMin, nextMax]);
  };

  return (
    <div className={styles.container}>
      <span>From</span>
      <Rating onChange={onChangeMin} value={valueMin} />
      <span>To</span>
      <Rating onChange={onChangeMax} value={valueMax} />
    </div>
  );
};

export default RatingRange;

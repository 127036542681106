import Whatshot from '@mui/icons-material/Whatshot';
import Slider from '../Slider';
import styles from './MapHeatmap.module.scss';
import Input from '../Input';

const HeatmapRangeSlider = ({ value, onChange }) => {
  const handleInputChange = (e) => {
    const newValue = Number(e);
    onChange(newValue);
  };

  return (
    <div className={styles.sliderContainer}>
      <Whatshot />
      <Slider
        min={0}
        max={100}
        value={value}
        onChange={onChange}
        step={1}
        debounceDelay={0}
        noSlots
      />
      <Input
        value={value}
        onChange={handleInputChange}
        type="number"
        size="small"
        className={styles.heatmapInput}
        min={0}
        max={100}
      />
    </div>
  );
};

export default HeatmapRangeSlider;

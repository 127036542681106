import useGPT from '@v1/lib/useGPT';
import { useMemo } from 'react';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import explainRecsGPTPrompt from '@/utils/explainRecsGPTPrompt';
import CANONICAL_TAG from '@/utils/canonicalTag';
import { paramNames } from '@/constants';
import { serializeInsightsParams } from '@/screens/Dashboard/DashboardPanel/helpers';

const useGPTExplainRecs = ({ category, baseParams }) => {
  const tagsParams = {
    parentType: category,
    tagTypes: CANONICAL_TAG,
    take: 10,
  };

  const { data: tagsData, error: tagsError, isLoading: tagsLoading } = useGetTagsQuery(tagsParams);

  const insightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: category,
      [paramNames.take]: 10,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category]);

  const { data: entitiesData, error: entitiesError, isLoading: entitiesLoading } = useGetInsightQuery(insightsParams);

  const isLoading = tagsLoading || entitiesLoading;
  const error = tagsError || entitiesError;

  const tags = tagsData?.results?.tags || [];
  const entities = entitiesData?.results?.entities || [];

  const prompt = explainRecsGPTPrompt({
    inputEntities: entities,
    recsEntities: tags,
    recsCategoryUrn: category,
  });

  const { result: gptResult, error: gptError, isLoading: gptLoading } = useGPT(prompt, {
    key: `gpt-v2-${JSON.stringify(prompt)}`,
  });

  return {
    isLoading: isLoading || gptLoading,
    error: error || gptError,
    result: isLoading || error ? null : gptResult,
  };
};

export default useGPTExplainRecs;

import React from 'react';
import clsx from 'clsx';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { closeDashboardById } from '@/store/slices/dashboardTabsSlice';
import styles from './DashboardTabs.module.scss';
import getTitle from './getTitle';

const getNextIndex = (index, length) => {
  if (index + 1 < length) {
    return index + 1;
  }

  if (index - 1 >= 0) {
    return index - 1;
  }

  return null;
};

const DashboardTab = ({
  id,
  dashboard,
  dashboardIds,
  index,
  isSelected,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = (e) => {
    e.preventDefault();
    if (!isSelected) {
      navigate(`/dashboards/view/${id}`);
    }
  };

  const handleDelete = async (e) => {
    e.stopPropagation();

    dispatch(closeDashboardById(id));

    if (isSelected) {
      const currentIndex = dashboardIds.indexOf(id);
      const nextIndex = getNextIndex(currentIndex, dashboardIds.length - 1);
      if (typeof nextIndex === 'number' && dashboardIds[nextIndex]) {
        navigate(`/dashboards/view/${dashboardIds[nextIndex]}`);
      } else {
        navigate('/dashboards/view/');
      }
    }
  };

  const title = getTitle(dashboard);

  return (
    <button
      className={clsx({
        [styles.tab]: true,
        [styles.tabActive]: isSelected,
      })}
      onClick={handleNavigate}
      type="button"
    >
      <X className={styles.icon} onClick={handleDelete} size={12} />
      <span className={styles.title}>{title}</span>
    </button>
  );
};

export default DashboardTab;

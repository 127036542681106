import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEntityBatchedRequestsContext, useTagBatchedRequestsContext } from '@/contexts';
import { fetchEntityStart } from '@/store/slices/entitiesSlice';
import { fetchTagStart } from '@/store/slices/tagsSlice';
import { getIsTagId } from '@/utils';

const EntityText = ({ className, entityId, index }) => {
  const isTagId = getIsTagId(entityId);

  const entityBatchedRequest = useEntityBatchedRequestsContext();
  const tagBatchedRequest = useTagBatchedRequestsContext();

  const dispatch = useDispatch();

  const entity = useSelector((state) => state.entities.byId[entityId]);
  const tag = useSelector((state) => state.tags.byId[entityId]);

  const fetchStart = isTagId ? fetchTagStart : fetchEntityStart;
  const queueArg = isTagId ? tagBatchedRequest.queueArg : entityBatchedRequest.queueArg;
  const value = isTagId ? tag : entity;

  const fetchIfNeeded = () => {
    if (!value) {
      dispatch(fetchStart(entityId));
      queueArg(entityId);
    }
  };

  useEffect(() => {
    fetchIfNeeded();
  }, []);

  return (
    <>
      {typeof index === 'number' && index > 0 ? <span>{',\u00A0'}</span> : null}
      <span className={clsx({ [className]: className })}>{value?.name || `${entityId.slice(0, 11)}...`}</span>
    </>
  );
};

export default EntityText;

import React from 'react';
import { Rating as MuiRating } from '@mui/material';

const Rating = ({ onChange, value, defaultValue }) => (
  <MuiRating
    onChange={(e, nextValue) => onChange(nextValue)}
    value={value}
    defaultValue={defaultValue}
  />
);
export default Rating;

import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { InlineButton } from '@/components';
import { paramNames } from '@/constants';
import { getIsFieldValueEmpty } from '@/utils';
import { useDescribeLocation } from '@/hooks';

import DemographicsLabel from './DemographicsLabel';
import LocationParam from '../LocationParam/LocationParam';

import { POPOVERS } from '../helpers/constants';
import getInterestsLabels from '../helpers/getInterestsLabel';
import { demographicsParamNames } from '../constants';

const QueryBuilder = ({
  children, params, onOpen, veltLocation,
}) => {
  const entitiesById = useSelector((state) => state.entities.byId);

  const location = useMemo(() => params[paramNames.signalLocation], [params]);

  const { displayLabel } = useDescribeLocation(location);

  const interestsLabel = useMemo(() => getInterestsLabels(params, entitiesById), [params, entitiesById]);

  const DemographicsComponent = useCallback(({ color, className }) => (
    <InlineButton
      color={color}
      className={className}
      onClick={onOpen(POPOVERS.demographics)}
    >
      <DemographicsLabel params={params} />
    </InlineButton>
  ), [onOpen, params]);

  const LocationComponent = useCallback(({ color, className }) => (
    <LocationParam
      color={color}
      className={className}
      location={location}
      onClick={onOpen(POPOVERS.locations)}
    />
  ), [location]);

  const InterestsComponent = useCallback(({ color, className }) => (
    <InlineButton
      color={color}
      className={className}
      onClick={onOpen(POPOVERS.interests)}
    >
      {interestsLabel}
    </InlineButton>
  ), [interestsLabel]);

  return (
    <div className={clsx(['comment-able'])} data-velt-location={JSON.stringify(veltLocation)}>
      {children({
        hasDemographics: demographicsParamNames.find((paramName) => !getIsFieldValueEmpty(params[paramName])),
        hasInterests: interestsLabel !== 'Anything',
        hasLocation: Boolean(location),

        demographicsLabel: <DemographicsLabel params={params} />,
        locationLabel: displayLabel,
        interestsLabel,

        openLocationPopover: onOpen(POPOVERS.locations),
        openDemographicsPopover: onOpen(POPOVERS.demographics),
        openInterestsPopover: onOpen(POPOVERS.interests),

        DemographicsComponent,
        InterestsComponent,
        LocationComponent,
      })}
    </div>
  );
};

export default QueryBuilder;

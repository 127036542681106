import { capitalize } from '@mui/material';

export const paramNames = {
  filterAddress: 'filter.address',
  filterEntities: 'filter.entities',
  filterExcludeEntities: 'filter.exclude.entities',
  filterExcludeTags: 'filter.exclude.tags',
  filterExternalExists: 'filter.external.exists',
  filterExternalResyCountMax: 'filter.external.resy.count.max',
  filterExternalResyCountMin: 'filter.external.resy.count.min',
  filterExternalResyParty_sizeMax: 'filter.external.resy.party_size.max',
  filterExternalResyParty_sizeMin: 'filter.external.resy.party_size.min',
  filterExternalResyRatingMax: 'filter.external.resy.rating.max',
  filterExternalResyRatingMin: 'filter.external.resy.rating.min',
  filterGeocodeAdmin1_region: 'filter.geocode.admin1_region',
  filterGeocodeAdmin2_region: 'filter.geocode.admin2_region',
  filterGeocodeCountry_code: 'filter.geocode.country_code',
  filterGeocodeName: 'filter.geocode.name',
  filterHours: 'filter.hours',
  filterLocationQuery: 'filter.location.query',
  filterLocationGeohash: 'filter.location.geohash',
  filterLocationRadius: 'filter.location.radius',
  filterLocation: 'filter.location',
  filterPopularityMax: 'filter.popularity.max',
  filterPopularityMin: 'filter.popularity.min',
  filterPrice_levelMax: 'filter.price_level.max',
  filterPrice_levelMin: 'filter.price_level.min',
  filterRatingMax: 'filter.rating.max',
  filterRatingMin: 'filter.rating.min',
  filterTags: 'filter.tags',
  filterType: 'filter.type',
  operatorExcludeTags: 'operator.exclude.tags',
  operatorFilterExternal: 'operator.filter.external',
  operatorFilterTags: 'operator.filter.tags',
  offset: 'offset',
  signalDemographicsAge: 'signal.demographics.age',
  signalDemographicsGender: 'signal.demographics.gender',
  signalDemographicsAudiences: 'signal.demographics.audiences',
  signalInterestsEntities: 'signal.interests.entities',
  signalInterestsTags: 'signal.interests.tags',
  signalLocationQuery: 'signal.location.query',
  signalLocationRadius: 'signal.location.radius',
  signalLocation: 'signal.location',
  sort_by: 'sort_by',
  take: 'take',

  // Internal use only
  audiences: 'audiences',
  filterPopularity: 'filter.popularity',
  filterPrice_level: 'filter.price_level',
  filterRating: 'filter.rating',
  filterTags_status: 'filter.tags_status',
  omnisearch: 'omnisearch',
};

export const paramNamesBitmap = Object.values(paramNames).reduce((obj, value) => ({ ...obj, [value]: 1 }), {});

const customLabels = {
  filterType: 'Category',
};

export const paramLabels = Object.entries(paramNames).reduce((obj, [key, value]) => {
  if (customLabels[key]) {
    return ({ ...obj, [value]: customLabels[key] });
  }

  // Add space before capital letters, replace _ with space, and lowercase
  const nextValue = key.replace(/([A-Z])|_/g, (match, p1) => (p1 ? ` ${ p1.toLowerCase()}` : ' '));
  return ({ ...obj, [value]: capitalize(nextValue) });
}, {});

import { FieldRenderer } from '@/components';
import { fieldByParamName } from '@/constants';
import ModalResetButton from '../ModalResetButton/ModalResetButton';
import styles from './FieldsModal.module.scss';

const SingleParamBody = ({
  paramName,
  baseParams,
  params,
  paramNames,
  onChange,
  onReset,
  showResetButton,
  resetPadding,
}) => {
  return (
    <div className={styles.bodySingle} style={resetPadding ? { padding: 0 } : {}}>
      <FieldRenderer
        disabled={baseParams[paramName]}
        field={fieldByParamName[paramName]}
        onChange={onChange(paramName)}
        params={params}
        value={params[paramName]}
        onReset={onReset}
      />
      {showResetButton && (
        <ModalResetButton
          onReset={onReset}
          paramNames={paramNames}
          params={params}
        />
      )}
    </div>
  );
};

export default SingleParamBody;

import React, { useMemo } from 'react';
import { ArrowDownRight } from 'react-feather';
import RGL, { WidthProvider } from 'react-grid-layout';

import DashboardLayoutItem from './DashboardLayoutItem';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { useDashboardVeltContext } from '../DashboardVeltProvider';
import { NUMBER_OF_COLUMNS } from '../helpers/constants';
import getLayoutItems from '../helpers/getLayoutPanels';
import styles from './DashboardLayout.module.scss';

const ReactGridLayout = WidthProvider(RGL);

const DashboardLayout = ({ dashboard }) => {
  const dashboardActions = useDashboardActionsContext();
  const dashboardVelt = useDashboardVeltContext();
  const { layout, panels } = dashboard;

  const layoutItems = useMemo(() => getLayoutItems(layout, panels), [layout, panels]);

  const onResizeOrReorder = (nextLayout) => {
    dashboardActions.updateLayout(nextLayout);
    dashboardVelt.onLayoutChangeEnd();
  };

  return (
    <ReactGridLayout
      cols={NUMBER_OF_COLUMNS}
      draggableHandle=".panel-drag-handle"
      layout={layoutItems}
      onDragStart={dashboardVelt.onLayoutChangeStart}
      onDragStop={onResizeOrReorder}
      onResizeStart={dashboardVelt.onLayoutChangeStart}
      onResizeStop={onResizeOrReorder}
      margin={[28, 28]}
      resizeHandle={(
        <div
          className={`${styles.resizableButton} react-resizable-handle`}
          data-velt-sync-access="true"
        >
          <ArrowDownRight size={14} />
        </div>
      )}
      rowHeight={100}
    >
      {/* TODO: Need to update CSS for border-radius, currently set to :root
                currently, we are unable to change it without inline styles
       */}
      {layoutItems.map((item) => (
        <div style={{ borderRadius: '1.5rem' }} key={item.i}>
          <DashboardLayoutItem
            item={item}
            panel={panels[item.i]}
            dashboard={dashboard}
          />
        </div>
      ))}
    </ReactGridLayout>
  );
};

export default DashboardLayout;

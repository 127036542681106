import React, { useEffect, useState } from 'react';
import { Button } from '@/components';

import styles from './CheckboxGroup.module.scss';

const CheckboxGroup = ({ options, value, onChange }) => {
  const [checkedValue, setCheckedValue] = useState()

  useEffect(() => {
    if (checkedValue !== value) {
      setCheckedValue(value)
    }
  }, [value]);

  const handleClick = (id) => {
    const nextValue = value === id ? null : id;

    onChange(nextValue);
  };

  return (
    <div className={styles.checkboxGroup}>
      {options.map((option) => (
        <Button
          key={option.id}
          onClick={() => handleClick(option.id)}
          className={styles.item}
          variant={option.id === value ? 'contained' : 'text'}
          color={option.color || 'primary'}
          size="small"
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}
export default CheckboxGroup;

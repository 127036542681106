/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { getIsFieldValueEmpty, getParamLabel } from '@/utils';

export const getQueryDataErrorMessage = (error) => {
  if (error) {
    if (error?.message) {
      return error?.message || 'Something went wrong';
    }

    return JSON.stringify(error);
  }

  return 'Something went wrong';
};
/**
 * Extracts the error, or list of errors from a given error query result.
 * It may either return a string error message, multiple error messages
 * joined in a single string, or null
 * @param queryError
 * @returns {null|*|string|string}
 */
export const getQueryError = (queryError) => {
  if (!queryError) {
    return null;
  }

  const { error, errors } = queryError.data || {};

  if (error) {
    return getQueryDataErrorMessage(error);
  }

  if (errors && errors.length) {
    return errors.map(getQueryDataErrorMessage).join('\n');
  }

  // Default message
  return getQueryDataErrorMessage();
};

export const getFiltersApplied = (params, filterParamNames) => (
  filterParamNames.filter((paramName) => !getIsFieldValueEmpty(params[paramName])).reduce((obj, paramName) => ({
    ...obj,
    [paramName]: getParamLabel({ paramName, value: params[paramName] }),
  }), {})
);

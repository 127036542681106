import ageRangeLabels from './ageRangeLabels';
import getAgeLabel from '../utils/getAgeLabel';
import fieldTypes from './fieldTypes';
import { paramNames } from './paramNames';

const fieldByParamName = {
  [paramNames.filterExternalExists]: {
    label: 'External Exists',
    options: [
      { value: 'resy', label: 'Resy' },
      { value: 'michelin', label: 'Michelin' },
      { value: 'tablet', label: 'Tablet' },
    ],
    type: fieldTypes.checkboxList,
  },
  [paramNames.filterPopularity]: {
    label: 'Popularity',
    type: fieldTypes.sliderWithInputs,
    min: 0,
    max: 1,
    defaultValue: [0, 1],
    step: 0.01,
  },
  [paramNames.filterPrice_level]: {
    label: 'Price',
    type: fieldTypes.rangeInputs,
  },
  [paramNames.filterRating]: {
    label: 'Rating',
    type: fieldTypes.ratingRange,
    defaultValue: [0, 5],
  },
  [paramNames.filterType]: {
    label: 'Category',
    type: fieldTypes.select,
  },
  [paramNames.omnisearch]: {
    label: 'Interests',
    type: fieldTypes.omnisearch,
  },
  [paramNames.signalDemographicsAge]: {
    label: 'Age',
    options: ageRangeLabels,
    type: fieldTypes.checkboxList,
    renderTag: (value) => getAgeLabel(value, ageRangeLabels.length),
  },
  [paramNames.signalDemographicsGender]: {
    label: 'Gender',
    options: [{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }],
    type: fieldTypes.radio,
  },
  [paramNames.audiences]: {
    type: fieldTypes.audiences,
    label: 'Audiences',
    // TODO: use new api to retrieve audience metadata
    renderTag: (value) => Object.keys(value).join(', '),
  },
  [paramNames.signalLocation]: {
    type: fieldTypes.location,
  },
  [paramNames.filterTags_status]: {
    type: fieldTypes.filterTags,
  },
  [paramNames.filterLocation]: {
    label: 'Location',
    type: fieldTypes.location,
  },
  [paramNames.offset]: {
  },
  [paramNames.take]: {},
};

export default fieldByParamName;

const hierarchyFromUrn = (urn) => urn?.replace(/^urn:(audience:)?/, '').split(':');
const isUrn = (maybeUrn) => maybeUrn?.startsWith?.('urn:');
const slugToSentenceCaseName = (slug) => (
  slug?.split(/^[-_]+/).map((word) => word[0].toUpperCase() + word.slice(1)).join(' ')
);

const bestGuessUrnName = (maybeUrn) => (
  hierarchyFromUrn(maybeUrn).map(slugToSentenceCaseName)
);

export default bestGuessUrnName;
